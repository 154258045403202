<template>
    <div class="container">

            <h1 class="is-size-2">{{ vassdragName }}</h1>
            <div class="columns">
                <div class="column is-2">
                    <field-select label-text="Fangstår" v-model="sampleYear" v-bind:options="sampleYears" />
                </div>
            </div>
            <h3 class="is-size-4 mb-5">Oppsummering prøver for valgt år</h3>

            <table class="is-hidden-mobile table is-fullwidth is-bordered is-striped is-narrow">
                <thead class="has-background-info-light">
                    <tr>
                        <th>Fangstår</th>
                        <th>Type</th>
                        <th>Laks</th>
                        <th>Aure</th>
                        <th>Regnbue</th>
                        <th>Pukkel</th>
                        <th>Sjørøye</th>
                        <th>Annet</th>
                        <th>Totalt antall</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="total in totalPrFisketype" :key="total.id">
                        <td>{{ total.sampleYear }}</td>
                        <td> <span>{{ getFishingType(total.fishingType) }}</span></td>
                        <td>{{ total.laks }}</td>
                        <td>{{ total.aure }}</td>
                        <td>{{ total.regnbueørret }}</td>
                        <td>{{ total.pukkellaks }}</td>
                        <td>{{ total.røye }}</td>
                        <td>{{ total.annet }}</td>
                        <td>{{ total.total }} </td>
                    </tr>
                </tbody>
            </table>


            <h3 class="is-size-4 mb-5">Analyser</h3>
            <table class="is-hidden-mobile table is-fullwidth is-bordered is-striped is-narrow">
                <thead class="has-background-info-light">
                    <tr>
                        <th>Fangstår</th>
                        <th>Dato start</th>
                        <th>Type</th>
                        <th>Ant prøver (gammel)</th>
                        <th>Laks</th>
                        <th>Aure</th>
                        <th>Regnbue</th>
                        <th>Pukkel</th>
                        <th>Sjørøye</th>
                        <th>Annet</th>
                        <th>Analysert av</th>
                        <th>Dato ferdig</th>
                        <th>Status</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="analyse in getAnalyser" v-bind:key="analyse.id">
                        <td>{{ analyse.sampleYear }}</td>
                        <td>{{ analyse.createdDate | formatDate }}</td>
                        <td>{{ getFishingType(analyse.fishingTypeId) }}</td>
                        <td>{{ analyse.numberOfSamples }}</td>
                        <td>{{analyse.arter.laks}}</td>
                        <td>{{ analyse.arter.aure }}</td>
                        <td>{{ analyse.arter.regnbueørret }}</td>
                        <td>{{ analyse.arter.pukkellaks }}</td>
                        <td>{{ analyse.arter.røye }}</td>
                        <td>{{ analyse.arter.annet }}</td>
                        <td>{{ analyse.analysedByName }}</td>
                        <td>{{ analyse.analyseCompleted | formatDate }}</td>
                        <td>
                            <span v-if="analyse.analyseCompleted != null" class="icon has-text-success"><i
                                    class="is-success fas fa-check"></i></span>
                            <span v-else class="icon has-text-grey-lighter"><i
                                    class="has-text-grey-lighter fas fa-check"></i></span>
                        </td>
                        <td><a @click="editAnalyse(analyse.id)">Velg</a> </td>
                    </tr>



                </tbody>
            </table>

            <button type="button" class="button is-info mr-1" @click="addNewAnalysis">
                <span class="icon is-small">
                    <i class="fa fa-plus"></i>
                </span>
                <span>Legg til ny analyse</span>
            </button>


        <div class="mt-5" v-show="registrerNy">
            <h3 class="is-size-4 mb-5">Ny analyse</h3>
            <ValidationObserver ref="form">
                <div class="field">
                    <ValidationProvider rules="required|validYear" v-slot="{ errors }">
                        <field-input label-text="Fangstår" v-model="nyAnalyse.sampleYear" name="Fangstår"></field-input>
                        <p class="help is-danger">{{ errors[0] }}</p>
                    </ValidationProvider>

                </div>
                <div class="field">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                        <label class="label">Dato</label>
                        <flat-pickr v-model="nyAnalyse.createdDate"></flat-pickr>
                        <p class="help is-danger">{{ errors[0] }}</p>
                    </ValidationProvider>

                </div>

                <div class="field">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                        <field-select label-text="Type fiske" v-model="nyAnalyse.fishingTypeId"
                            v-bind:options="fishingTypes" />
                        <p class="help is-danger">{{ errors[0] }}</p>
                    </ValidationProvider>
                </div>

                <div class="field">
                    <AntallArter :model="nyAnalyse.arter" :isDisabled="false"></AntallArter>
                </div>

                <div class="field">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                        <field-select label-text="Analysert av" v-model="nyAnalyse.analysedById"
                            v-bind:options="analytikere" />
                        <p class="help is-danger">{{ errors[0] }}</p>
                    </ValidationProvider>
                </div>

                <div class="field">
                    <label class="label">Dato ferdig analysert</label>
                    <flat-pickr v-model="nyAnalyse.analyseCompleted"></flat-pickr>
                </div>

                <div class="field is-grouped mb-3">
                    <div class="control">
                        <button class="button is-info" @click="registrerNyAnalyse()">
                            <span class="icon is-small">
                                <i class="fa fa-save"></i>
                            </span>
                            <span>Registrer</span></button>
                    </div>
                    <div class="control">
                        <button class="button is-default" @click="registrerNy = false">
                            <span class="icon is-small">
                                <i class="fa fa-times"></i>
                            </span>
                            <span>Avbryt</span>
                        </button>
                    </div>
                    <div class="control" v-if="nyAnalyse.id > 0">
                        <button class="button is-danger is-outlined" @click="slettAnalyse()">
                            <span class="icon is-small">
                                <i class="fa fa-trash"></i>
                            </span>
                            <span>Slett</span>
                        </button>
                    </div>
                </div>
            </ValidationObserver>
        </div>

        <button class="button is-default" @click="$router.push({ name: 'aktivitet' })">Tilbake</button>
        <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>

    </div>
</template>
  
<script>
import FieldSelect from '../../globals/field-select.vue';
import api from "@/api/basicApi.js";
import { FishingTypeEnum } from "@/enums/FishingTypeEnum";
import ConfirmDialogue from '@/components/modals/ConfirmDialogue.vue';
//import VelgArter from '../../grouped/VelgArter.vue';
import AntallArter from '@/components/grouped/AntallArter.vue';


export default {

    data: function () {
        return {
            totalPrFisketype: [],
            vassdragName: "",
            analytikere: [],
            fishingTypes: FishingTypeEnum,
            ferdigAnalysert: false,
            analyser: [],
            registrerNy: false,
            nyAnalyse: {
                vassdragId: this.$route.params.vassdragId,
                species: '',
                arter: {
                    laks: 0,
                    aure: 0,
                    røye: 0,
                    pukkellaks: 0,
                    regnbueørret: 0,
                    annet: 0,
                    annetTekst: ''
                }
            },
            sampleYears: [],
            sampleYear: null

        }
    },
    components: {
        FieldSelect,
        AntallArter,
        "confirm-dialogue": ConfirmDialogue
    },
    mounted() {
        let self = this;
        self.getOppsummeringOgAnalyser();
        api.fetchData({ url: "MottakFiskeskjellAktivitet/GetUsers" })
            .then(res => {
                self.analytikere = res.data

            });
        api.fetchData({ url: "MottakFiskeskjellAktivitet/GetSampleYears" })
            .then(res => {
                self.sampleYears = res.data.sampleYears
            });


    },
    computed: {
        getAnalyser() {
            return this.analyser
        }

    },
    methods: {
        getOppsummeringOgAnalyser() {
            let self = this
            let vassdragId = self.$route.params.vassdragId
            let fylkeId = self.$route.params.fylkeId
            let funnsted = self.$route.params.funnsted
            if (self.sampleYear == null) {
                self.sampleYear = self.$route.params.sampleYear;
            }

            api.fetchData({ url: `MottakFiskeskjellAnalyse/GetAggregatedSamplesInfoYearForVassdrag/${vassdragId}/${fylkeId}/${funnsted}/${self.sampleYear}` })
                .then(res => {
                    self.totalPrFisketype = res.data.antallPrøverAggregert
                    self.vassdragName = res.data.vassdragName
                });

            api.fetchData({ url: `MottakFiskeskjellAnalyse/GetAnalysisesForVassdragYear/${vassdragId}/${fylkeId}/${funnsted}/${self.sampleYear}` })
                .then(res => {
                    self.analyser = res.data

                });
        },
        registrerNyAnalyse() {
            let self = this;
            self.$refs.form.validate().then(success => {
                if (!success) {
                    return;
                }
                else {
                    api.saveData({ url: "MottakFiskeskjellAnalyse/SaveAnalysisForVassdrag/", data: self.nyAnalyse })
                        .then(res => {
                            self.registrerNy = false;
                            const index = self.analyser.findIndex(a => a.id === res.data.id)
                            if (index === -1) {
                                self.analyser.push(res.data)
                            } else {
                                self.analyser[index] = res.data
                            }
                        })
                }
            })
        },
        editAnalyse(id) {
            var analyseToEdit = this.analyser.find(a => a.id === id);
            const copiedAnalyse = { ...analyseToEdit };
            this.nyAnalyse = copiedAnalyse;
            this.registrerNy = true;
        },
        addNewAnalysis() {
            this.nyAnalyse = {
                sampleYear: this.sampleYear,
                vassdragId: this.$route.params.vassdragId,
                funnsted: this.$route.params.funnsted,
                fylkeId: this.$route.params.fylkeId,
                species: '',
                arter: {
                    laks: 0,
                    aure: 0,
                    røye: 0,
                    pukkellaks: 0,
                    regnbueørret: 0,
                    annet: 0,
                    annetTekst: ''
                }
            }
            this.registrerNy = true;
        },
        getFishingType(id) {
            return this.fishingTypes.find(x => x.id == id).name;
        },
        slettAnalyse() {
            let self = this
            this.$refs.confirmDialogue.show({
                title: 'Slett analyse',
                message: 'Er du sikker på at du vil slette analysen?',
                okButton: 'Slett',
            }).then((result) => {
                if (result) {
                    api.deleteData({ url: "MottakFiskeskjellAnalyse/SlettAnalyse/" + self.nyAnalyse.id }).then(res => {
                        if (res) {
                            self.registrerNy = false;
                            const index = self.analyser.findIndex(a => a.id === self.nyAnalyse.id)
                            self.analyser.splice(index, 1)
                        }
                    })
                }
            })
        },
        updateSpecies(newVal) {
            this.nyAnalyse.species = newVal
        }

    },
    watch: {
        'sampleYear'(newVal) {
            if (newVal != null) {
                this.registrerNy = false;
                this.getOppsummeringOgAnalyser()
            }

        }
    }
};
</script>
<style scoped>
.table td {
    vertical-align: middle;
}

.center {
    margin-top: 35px;
}
</style>
  