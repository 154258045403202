<template>
  <div class="search">
    <section class="section">

      <div class="container">
        <div class="columns">
          <div class="column">
            <h1 class="title">Søk etter prøver for individ</h1>
          </div>
          <div class="column">
            <span class="icon-text is-pulled-right">
              <span class="icon has-text-primary">
                <i class="fas fa-star"></i>
              </span>
              <span><strong>{{ samplesTotal }}</strong> prøver i basen</span>
            </span>
          </div>
        </div>

        <div class="box">
          <div class="columns">
            <div class="column">
              <h2 class="subtitle">Filter</h2>
            </div>
            <div class="column"> <button class="button is-small is-default is-pulled-right" @click="togglePanel">
                {{ isPanelOpen ? "Lukk" : "Åpne" }}
              </button></div>
          </div>

          <transition name="slide">
            <div v-if="isPanelOpen" class="content">
              <div class="columns">
                <div class="column">
                  <div class="field">
                    <label class="label">Id</label>
                    <p class="control is-expanded">
                      <input class="input" type="text" v-model="filters.searchableId"
                        placeholder="Søk på strekkode, individ-id, prøve-id" />
                    </p>

                  </div>
                </div>

                <div class="column">
                  <label class="label">Artsgrupper</label>
                  <multiselect v-model="filters.globalTypeIds" :options="globalTypes" :multiple="true"
                    :preserve-search="true" placeholder="Velg en eller flere" label="name" track-by="name"
                    :preselect-first="false">
                    <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single"
                        v-if="filters.globalTypeIds.length" v-show="!isOpen">{{ filters.globalTypeIds.length }}
                        grupper
                        valgt</span></template>
                  </multiselect>
                </div>

              </div>
              <div class="columns">
                <div class="column">
                  <label class="label">Art</label>
                  <multiselect v-model="filters.species" :options="species" :multiple="true" :preserve-search="true"
                    placeholder="Velg en eller flere" label="name" track-by="id" :preselect-first="false">
                    <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single"
                        v-if="filters.species.length" v-show="!isOpen">{{ filters.species.length }} arter
                        valgt</span></template>
                  </multiselect>
                </div>
                <div class="column">
                  <label class="label">Prøvetype</label>
                  <multiselect v-model="filters.sampleMaterialIds" :options="sampleMaterials" :multiple="true"
                    :preserve-search="true" placeholder="Velg en eller flere" label="name" track-by="name"
                    :preselect-first="false">
                    <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single"
                        v-if="filters.sampleMaterialIds.length" v-show="!isOpen">{{ filters.sampleMaterialIds.length
                        }}
                        prøvetyper
                        valgt</span></template>
                  </multiselect>
                </div>
                <div class="column">
                  <label class="label">Lagringssted</label>
                  <multiselect v-model="filters.storageLocationIds" :options="storageLocations" :multiple="true"
                    :preserve-search="true" placeholder="Velg en eller flere" label="name" track-by="name"
                    :preselect-first="false">
                    <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single"
                        v-if="filters.storageLocationIds.length" v-show="!isOpen">{{ filters.storageLocationIds.length
                        }}
                        lagringssted
                        valgt</span></template>
                  </multiselect>
                </div>
                <div class="column">
                  <label class="label">Lagringsmedium</label>
                  <multiselect v-model="filters.storageMediumIds" :options="storageMediums" :multiple="true"
                    :preserve-search="true" placeholder="Velg en eller flere" label="name" track-by="name"
                    :preselect-first="false">
                    <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single"
                        v-if="filters.storageMediumIds.length" v-show="!isOpen">{{ filters.storageMediumIds.length }}
                        lagringsmedium
                        valgt</span></template>
                  </multiselect>
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <label class="label">Mottat dato fra</label>
                  <flat-pickr v-model="filters.dateReceivedFrom"></flat-pickr>
                </div>
                <div class="column">
                  <label class="label">Mottat dato til</label>
                  <flat-pickr v-model="filters.dateReceivedTo"></flat-pickr>
                </div>
                <div class="column">
                  <label class="label">Prøvetakingsdato fra</label>
                  <flat-pickr v-model="filters.sampleDateFrom"></flat-pickr>
                </div>
                <div class="column">
                  <label class="label">Prøvetakingsdato til</label>
                  <flat-pickr v-model="filters.sampleDateTo"></flat-pickr>
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <label class="label">Kommune</label>
                  <multiselect v-model="filters.municipalityIds" :options="municipalities" :multiple="true"
                    :preserve-search="true" placeholder="Velg en eller flere" label="name" track-by="name"
                    :preselect-first="false">
                    <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single"
                        v-if="filters.municipalityIds.length" v-show="!isOpen">{{ filters.municipalityIds.length }}
                        kommuner
                        valgt</span></template>
                  </multiselect>
                </div>
                <div class="column">
                  <label class="label">Villreinområde</label>
                  <multiselect v-model="filters.wildRaindeerAreaIds" :options="wildRaindeerAreas" :multiple="true"
                    :preserve-search="true" placeholder="Velg en eller flere" label="name" track-by="name"
                    :preselect-first="false">
                    <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single"
                        v-if="filters.wildRaindeerAreaIds.length" v-show="!isOpen">{{
                          filters.wildRaindeerAreaIds.length
                        }} villreinomr
                        valgt</span></template>
                  </multiselect>
                </div>

                <div class="column">
                  <label class="label">Prosjekt</label>
                  <multiselect v-model="filters.projectIds" :options="projects" :multiple="true" :preserve-search="true"
                    placeholder="Velg en eller flere" label="name" track-by="name" :preselect-first="false">
                    <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single"
                        v-if="filters.projectIds.length" v-show="!isOpen">{{ filters.projectIds.length }} prosjekter
                        valgt</span></template>
                  </multiselect>
                </div>
                <div class="column">
                  <label class="label">Oppdragsgiver</label>
                  <multiselect v-model="filters.ownerIds" :options="owners" :multiple="true" :preserve-search="true"
                    placeholder="Velg en eller flere" label="name" track-by="name" :preselect-first="false">
                    <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single"
                        v-if="filters.ownerIds.length" v-show="!isOpen">{{ filters.ownerIds.length }} oppdragsgivere
                        valgt</span></template>
                  </multiselect>
                </div>

              </div>
              <div class="columns">
                <div class="column">

                  <a class="button is-info" @click="getSamples()">
                    <span class="icon">
                      <i class="fas fa-search"></i>
                    </span>
                    <span>Søk</span>
                  </a>
                  <a class="button is-default ml-2" @click="clearFilters()">
                    <span class="icon">
                      <i class="fa fa-times"></i>
                    </span>
                    <span>Nullstill filtre</span>
                  </a>

                </div>
              </div>
            </div>
          </transition>
        </div>

        <div class="columns">
          <div class="column">
            <multiselect v-model="selectedColumns" :options="columnsOptions" :multiple="true" :close-on-select="false"
              :clear-on-select="false" :preserve-search="true" placeholder="Vis flere kolonner" label="name"
              track-by="name" :preselect-first="false">
              <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single"
                  v-if="selectedColumns.length" v-show="!isOpen">{{ selectedColumns.length }} kolonner
                  valgt</span></template>
            </multiselect>
          </div>

          <div class="column">
            <div class="control">
              <button v-if="isAdminOrRegisterRolle" :disabled="!isBulkEdit" @click="showBulkEditDialogue"
                class="button is-small is-outlined is-info is-pulled-right ml-2">
                <span class="icon is-small">
                  <i class="fas fa-pen"></i>
                </span>
                <span>Endre flere</span>
              </button>
              <button class="button is-outlined is-small is-info is-pulled-right ml-2" @click="exportCsv()">
                <span class="icon is-small">
                  <i class="fas fa-download"></i>
                </span>
                <span>Last ned</span>
              </button>
            </div>
          </div>
        </div>

        <div class="columns">
          <div class="column is-full">
            <div class="table-container">
              <table class="table is-fullwidth is-bordered is-striped is-narrow">
                <thead>
                  <tr>
                    <th><input :disabled=!isAdminOrRegisterRolle type="checkbox" v-model="checkAll"></th>
                    <th>
                      <abbr title="Ekstern eller intern id">Individ id</abbr>
                    </th>
                    <th @click="sort('individualSpeciesName')">
                      Art
                      <div class="is-pulled-right">
                        <div v-show="currentSort !== 'individualSpeciesName'"><i class="fas fa-sort"></i></div>
                        <div v-show="currentSort === 'individualSpeciesName' && sortDirection == 'asc'"> <i
                            class="fas fa-sort-up"></i></div>
                        <div v-show="currentSort === 'individualSpeciesName' && sortDirection == 'desc'"> <i
                            class="fas fa-sort-down"></i></div>
                      </div>

                    </th>
                    <th @click="sort('sampleMaterialName')">
                      Prøvetype
                      <div class="is-pulled-right">
                        <div v-show="currentSort !== 'sampleMaterialName'"><i class="fas fa-sort"></i></div>
                        <div v-show="currentSort === 'sampleMaterialName' && sortDirection == 'asc'"> <i
                            class="fas fa-sort-up"></i></div>
                        <div v-show="currentSort === 'sampleMaterialName' && sortDirection == 'desc'"> <i
                            class="fas fa-sort-down"></i></div>
                      </div>
                    </th>

                    <th @click="sort('storageLocationName')">
                      Lagringssted
                      <div class="is-pulled-right">
                        <div v-show="currentSort !== 'storageLocationName'"><i class="fas fa-sort"></i></div>
                        <div v-show="currentSort === 'storageLocationName' && sortDirection == 'asc'"> <i
                            class="fas fa-sort-up"></i></div>
                        <div v-show="currentSort === 'storageLocationName' && sortDirection == 'desc'"> <i
                            class="fas fa-sort-down"></i></div>
                      </div>
                    </th>

                    <th @click="sort('storageMediumName')">
                      <abbr title="Lagringsmedium">Medium</abbr>
                      <div class="is-pulled-right">
                        <div v-show="currentSort !== 'storageMediumName'"><i class="fas fa-sort"></i></div>
                        <div v-show="currentSort === 'storageMediumName' && sortDirection == 'asc'"> <i
                            class="fas fa-sort-up"></i></div>
                        <div v-show="currentSort === 'storageMediumName' && sortDirection == 'desc'"> <i
                            class="fas fa-sort-down"></i></div>
                      </div>
                    </th>
                    <th @click="sort('dateReceived')">
                      Mottatt
                      <div class="is-pulled-right">
                        <div v-show="currentSort !== 'dateReceived'"><i class="fas fa-sort"></i></div>
                        <div v-show="currentSort === 'dateReceived' && sortDirection == 'asc'"> <i
                            class="fas fa-sort-up"></i>
                        </div>
                        <div v-show="currentSort === 'dateReceived' && sortDirection == 'desc'"> <i
                            class="fas fa-sort-down"></i>
                        </div>
                      </div>
                    </th>
                    <th @click="sort('sampleDate')">
                      Prøvetakingsdato
                      <div class="is-pulled-right">
                        <div v-show="currentSort !== 'sampleDate'"><i class="fas fa-sort"></i></div>
                        <div v-show="currentSort === 'sampleDate' && sortDirection == 'asc'"> <i
                            class="fas fa-sort-up"></i>
                        </div>
                        <div v-show="currentSort === 'sampleDate' && sortDirection == 'desc'"> <i
                            class="fas fa-sort-down"></i>
                        </div>
                      </div>
                    </th>
                    <th v-show="showColumn('Prosjekt')" @click="sort('projectName')">
                      Prosjekt
                      <div class="is-pulled-right">
                        <div v-show="currentSort !== 'projectName'"><i class="fas fa-sort"></i></div>
                        <div v-show="currentSort === 'projectName' && sortDirection == 'asc'"> <i
                            class="fas fa-sort-up"></i>
                        </div>
                        <div v-show="currentSort === 'projectName' && sortDirection == 'desc'"> <i
                            class="fas fa-sort-down"></i>
                        </div>
                      </div>
                    </th>

                    <th v-show="showColumn('Oppdragsgiver')">
                      Oppdragsgiver
                    </th>

                    <th v-show="showColumn('Kommune')" @click="sort('individualMunicipalityName')">
                      Kommune
                      <div class="is-pulled-right">
                        <div v-show="currentSort !== 'individualMunicipalityName'"><i class="fas fa-sort"></i></div>
                        <div v-show="currentSort === 'individualMunicipalityName' && sortDirection == 'asc'"> <i
                            class="fas fa-sort-up"></i></div>
                        <div v-show="currentSort === 'individualMunicipalityName' && sortDirection == 'desc'"> <i
                            class="fas fa-sort-down"></i></div>
                      </div>

                    </th>
                    <th v-show="showColumn('Villreinområde')" @click="sort('individualWildRaindeerAreaName')">
                      Villreinområde
                      <div class="is-pulled-right">
                        <div v-show="currentSort !== 'individualWildRaindeerAreaName'"><i class="fas fa-sort"></i></div>
                        <div v-show="currentSort === 'individualWildRaindeerAreaName' && sortDirection == 'asc'"> <i
                            class="fas fa-sort-up"></i></div>
                        <div v-show="currentSort === 'individualWildRaindeerAreaName' && sortDirection == 'desc'"> <i
                            class="fas fa-sort-down"></i></div>
                      </div>
                    </th>
                    <th v-show="showColumn('Funnsted')" @click="sort('individualPlace')">
                      Funnsted
                      <div class="is-pulled-right">
                        <div v-show="currentSort !== 'individualPlace'"><i class="fas fa-sort"></i></div>
                        <div v-show="currentSort === 'individualPlace' && sortDirection == 'asc'"> <i
                            class="fas fa-sort-up"></i>
                        </div>
                        <div v-show="currentSort === 'individualPlace' && sortDirection == 'desc'"> <i
                            class="fas fa-sort-down"></i></div>
                      </div>
                    </th>
                  </tr>

                </thead>
                <tbody>
                  <tr v-for="sample in sortedSamples" :key="sample.id">
                    <td><label class="checkbox">
                        <input :disabled=!isAdminOrRegisterRolle type="checkbox" @click="toggleValgteEnheter(sample.id)"
                          v-model="valgteProver" :value="sample.id">
                      </label> </td>
                    <td>
                      <router-link :to="{
                        name: 'Individual',
                        params: { individualId: sample.individualId }
                      }">
                        <span class="has-text-weight-semibold">{{
                          sample.individualExternalId
                        }}</span>
                        <span class="has-text-weight-semibold" v-show="sample.individualInternalId !== '0' &&
                          sample.individualInternalId !== null
                          ">
                          {{ sample.individualInternalId }}</span>
                      </router-link>
                    </td>
                    <td>{{ sample.individualSpeciesName }}</td>

                    <td>{{ sample.sampleMaterialName }}</td>
                    <td>{{ sample.storageLocationName }}</td>

                    <td>{{ sample.storageMediumName }}</td>
                    <td>{{ sample.dateReceived | formatDate }}</td>
                    <td>{{ sample.sampleDate | formatDate }}</td>
                    <td v-show="showColumn('Prosjekt')">
                      <div class="has-tooltip-info has-tooltip-arrow has-tooltip-right has-tooltip-multiline"
                        v-bind:value="sample.projectName" v-bind:data-tooltip="sample.projectDescription">
                        {{ sample.projectName }}
                      </div>
                    </td>

                    <td v-show="showColumn('Oppdragsgiver')">
                      <div v-show="sample.numberOfOwners > 0"
                        class="has-tooltip-info has-tooltip-arrow has-tooltip-right has-tooltip-multiline"
                        v-bind:value="sample.numberOfOwners" v-bind:data-tooltip="sample.ownerNames">
                        {{ sample.numberOfOwners }}
                      </div>
                    </td>
                    <td v-show="showColumn('Kommune')">
                      {{ sample.individualMunicipalityName }}
                      {{ sample.individualMunicipalityId }}
                    </td>
                    <td v-show="showColumn('Villreinområde')">{{ sample.individualWildRaindeerAreaName }}</td>
                    <td v-show="showColumn('Funnsted')">{{ sample.individualPlace }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <bulk-edit v-if="isAdminOrRegisterRolle" ref="bulkEditDialog" @valuesToUpdate="updateForSelected"
          :numberOfSamplesSelected="valgteProver.length"></bulk-edit>
      </div>
    </section>
  </div>
</template>

<script>
import BulkEditSamples from '@/components/modals/BulkEditSamples.vue';
import api from "@/api/basicApi.js";
import { PROVEBASE_CONFIG } from "@/../config.js";

export default {
  data: function () {
    return {
      isPanelOpen: true,
      defaultGlobalTypes: [1, 2, 6, 7],
      filters: this.initFilter(),
      selectedColumns: [],
      columnsOptions: [
        { name: 'Kommune' },
        { name: 'Villreinområde' },
        { name: 'Funnsted' },
        { name: 'Prosjekt' },
        { name: 'Oppdragsgiver' }
      ],
      searchableId: "",
      samples: [],
      samplesReturned: 0,
      samplesTotal: 0,
      species: [],
      sampleMaterials: [],
      storageMediums: [],
      municipalities: [],
      wildRaindeerAreas: [],
      globalTypes: [],
      storageLocations: [],
      projects: [],
      funnsted: '',
      owners: [],
      valgteProver: [],
      currentSort: '',
      sortDirection: 'asc',
      page: 1


    };
  },
  components: {
    "bulk-edit": BulkEditSamples
  },
  computed: {
    manyHits() {
      return this.samplesReturned >= 100;
    },
    isBulkEdit() {
      return this.valgteProver.length > 0
    },
    checkAll: {
      get: function () {
        return this.samples ? this.valgteProver.length == this.samples.length : false;
      },
      set: function (value) {
        var valgteEnheter = [];
        if (value) {
          this.samples.forEach(function (enhet) {
            valgteEnheter.push(enhet.id);
          });
        }
        this.valgteProver = valgteEnheter;
      }
    },
    sortedSamples() {
      return [...this.samples].sort((a, b) => {
        let modifier = this.sortDirection === 'desc' ? -1 : 1;

        const aValue = a[this.currentSort];
        const bValue = b[this.currentSort];

        if (!aValue && aValue != 0) return 1;
        if (!bValue && bValue != 0) return -1;


        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
    },
    isAdminOrRegisterRolle() {
      const user = JSON.parse(localStorage.getItem("user"));
      if (user.accountRoles.includes(1) || user.accountRoles.includes(2)) {
        return true;
      }
      return false;
    },
  },

  mounted() {
    this.getSamples();
    this.getDropdownsForFilters();
    this.getSamplesInfiniteScroll()
  },
  methods: {

    sort(columnToSort) {
      if (this.currentSort === columnToSort) {
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc'
      }
      this.currentSort = columnToSort
    },

    togglePanel() {
      this.isPanelOpen = !this.isPanelOpen;
    },
    clearSelected() {
      this.valgteProver = []
    },
    initFilter() {
      return {
        searchableId: null,
        species: [],
        municipalityIds: [],
        storageLocationIds: [],
        sampleMaterialIds: [],
        storageMediumIds: [],
        wildRaindeerAreaIds: [],
        projectIds: [],
        ownerIds: [],
        globalTypeIds: [],
        dateReceivedFrom: null,
        dateReceivedTo: null,
        sampleDateFrom: null,
        sampleDateTo: null
      }
    },
    clearFilters() {
      this.filters = this.initFilter()
    },
    showColumn(columnName) {
      return this.selectedColumns.some(c => c.name == columnName)
    },

    getFilters() {
      let self = this
      let filters = {
        speciesIds: (self.filters.species || []).map(s => s.id),
        sampleMaterialIds: (self.filters.sampleMaterialIds || []).map(s => s.id),
        storageLocationIds: (self.filters.storageLocationIds || []).map(s => s.id),
        storageMediumIds: (self.filters.storageMediumIds || []).map(s => s.id),
        searchableId: self.filters.searchableId,
        globalTypeIds: (self.filters.globalTypeIds || []).map(g => g.id),
        ownerIds: (self.filters.ownerIds || []).map(o => o.id),
        projectIds: (self.filters.projectIds || []).map(p => p.id),
        municipalityIds: (self.filters.municipalityIds || []).map(m => m.id),
        wildRaindeerAreaIds: (self.filters.wildRaindeerAreaIds || []).map(w => w.id),
        dateReceivedFrom: self.filters.dateReceivedFrom,
        dateReceivedTo: self.filters.dateReceivedTo,
        sampleDateFrom: self.filters.sampleDateFrom,
        sampleDateTo: self.filters.sampleDateTo
      }

      return filters

    },

    getDropdownsForFilters() {
      let self = this;
      api.postData({ url: "Search/GetDropdownsForFilter", data: self.defaultGlobalTypes }).then(res => {
        self.species = res.data.species;
        self.municipalities = res.data.municipalities;
        self.wildRaindeerAreas = res.data.wildRaindeerArea;
        self.projects = res.data.projects;
        self.sampleMaterials = res.data.sampleMaterials;
        self.storageLocations = res.data.storageLocations;
        self.storageMediums = res.data.storageMediums;
        self.owners = res.data.owners;
        self.globalTypes = res.data.globalTypes;
      });
    },
    getSamples() {
      this.page = 1
      let filters = this.getFilters()
      let self = this
      api.postData({ url: "Search/GetSamples/", data: filters }).then(res => {
        self.samples = res.data.samples
        self.samplesReturned = res.data.samplesReturned;
        self.samplesTotal = res.data.samplesTotal;
      });
    },
    getSamplesInfiniteScroll() {
      window.onscroll = () => {
        let bottomOfWindow = Math.trunc(document.documentElement.scrollHeight - document.documentElement.scrollTop) === document.documentElement.clientHeight;
        if (bottomOfWindow) {
          let self = this
          this.page += 1;
          api.postData({ url: "Search/GetSamples/" + self.page, data: this.getFilters() }).then(res => {
            self.samples.push(...res.data.samples);
          });
        }
      }
    },

    exportCsv() {
      let self = this
      api.fetchData({ url: "Search/GetEncryptedToken" }).then(res => {
        var tokenFromServer = res.data;
        let queryString = "encryptedToken=" + tokenFromServer;
        if (self.filters["searchableId"] != null) {
          queryString += "&searchableId=" + self.filters["searchableId"];
        }
        if (self.filters["dateReceivedFrom"] != null) {
          queryString += '&dateReceivedFrom=' + self.filters.dateReceivedFrom
        }
        if (self.filters["dateReceivedTo"] != null) {
          queryString += '&dateReceivedTo=' + self.filters.dateReceivedTo
        }
        if (self.filters["sampleDateFrom"] != null) {
          queryString += '&sampleDateFrom=' + self.filters.sampleDateFrom
        }
        if (self.filters["sampleDateTo"] != null) {
          queryString += '&sampleDateTo=' + self.filters.sampleDateTo
        }
        self.filters.species.forEach(s => {
          queryString += '&speciesIds=' + s.id
        })
        self.filters.sampleMaterialIds.forEach(s => {
          queryString += '&sampleMaterialIds=' + s.id
        })
        self.filters.storageLocationIds.forEach(s => {
          queryString += '&storageLocationIds=' + s.id
        })
        self.filters.storageMediumIds.forEach(s => {
          queryString += '&storageMediumIds=' + s.id
        })
        self.filters.globalTypeIds.forEach(s => {
          queryString += '&globalTypeIds=' + s.id
        })
        self.filters.ownerIds.forEach(s => {
          queryString += '&ownerIds=' + s.id
        })
        self.filters.projectIds.forEach(s => {
          queryString += '&projectIds=' + s.id
        })
        self.filters.municipalityIds.forEach(s => {
          queryString += '&municipalityIds=' + s.id
        })
        self.filters.wildRaindeerAreaIds.forEach(s => {
          queryString += '&wildRaindeerAreaIds=' + s.id
        })

        window.location.href =
          `${PROVEBASE_CONFIG.API_URL}/` + "search/ExportAsCsv?" + queryString;
      });
    },
    showBulkEditDialogue() {
      this.$refs.bulkEditDialog.show();
    },
    updateForSelected(valuesToUpdate) {
      let self = this
      let bulkEditDto = {
        SamplesToEdit: this.valgteProver,
        ValuesToUpdate: valuesToUpdate
      }
      api.saveData({ url: "Prove/BulkEdit/", data: bulkEditDto }).then(function () {
        self.clearSelected()
        self.getSamples()
      })

    },
    toggleValgteEnheter(id) {
      const index = this.valgteProver.indexOf(id);
      if (index === -1) {
        this.valgteProver.push(id);
      } else {
        this.valgteProver.splice(index, 1);
      }
    },
  },

};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
.filter-card {
  position: absolute;
  z-index: 100;
}


.slide-enter-active,
.slide-leave-active {
  transition: all 0.4s ease;
  overflow: hidden;
}

.slide-enter-from,
.slide-leave-to {
  max-height: 0;
  opacity: 0;
  transform: scaleY(0.9);
  padding: 0;
}

.slide-enter-to,
.slide-leave-from {
  max-height: 600px;
  opacity: 1;
  transform: scaleY(1);

}
</style>
