<template>
  <div class="createIndividual">
    <section>
      <div class="columns">
        <div class="column">
          <h2 class="subtitle is-inline-block">{{ title }}</h2>

          <button v-show="animal.id == 0" class="button is-small is-text ml-2" @click="copyFromPreviousIndividual"> <i
              class="fas fa-copy"></i> <span class="ml-1">Kopier fra nyeste</span>
          </button>
        </div>
        <div class="column">
          <div class="is-pulled-right">
          <button type="submit" @click="save" class="button is-info">
                <span class="icon is-small">
                  <i class="fa fa-save"></i>
                </span>
                <span>Lagre</span>
              </button>

              <button type="button" class="button is-default ml-2" v-on:click="cancel">
                <span class="icon is-small">
                  <i class="fa fa-times"></i>
                </span>
                <span>Avbryt</span>
              </button>
            </div>
        </div>
      </div>
      <ValidationObserver ref="form">
        <form @submit.prevent="save, copyFromPreviousIndividual">
          <fieldset>
           <div class="columns">
              <div class="column">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <field-select label-text="Gruppe *" v-model="animal.speciesGlobalTypeId" v-bind:options="globalTypes"
                    :error="errors[0]" />
                </ValidationProvider>
              </div>
              <div class="column">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <field-select label-text="Art *" v-model="animal.speciesId" v-bind:options="species" placeholder="Art"
                    :error="errors[0]" />
                </ValidationProvider>
              </div>
              <div class="column">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <field-select label-text="Kategori *" v-model="animal.individualCategoryId" v-bind:options="categories"
                    placeholder="Kategori" :error="errors[0]" />
                </ValidationProvider>
              </div>
              <div class="column" v-if="!multipleIds">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <field-input label-text="Intern id *" v-model="animal.internalId" :error="errors[0]" />
                </ValidationProvider>
              </div>
              <div class="column" v-if="multipleIds">
                <ValidationProvider rules="required" v-slot="{ errors }">
                <label class="label">Intern id *</label>
                <textarea class="textarea" v-model="multipleInternalIds" placeholder="Liste med flere interne id'er under hverandre"></textarea>
                <p class="error">{{ errors[0] }}</p>
              </ValidationProvider>
              </div>
              <div class="column">
                <ValidationProvider rules="" v-slot="{ errors }">
                  <field-input :disable="multipleIds" label-text="Ekstern id" v-model="animal.externalId" :error="errors[0]" />
                </ValidationProvider>
              </div>
            
            </div>
            <div class="columns">
              
              <div class="column is-4">
                <div class="field">
                  <div v-show="animal.mottakId > 0">
                    <label class="label">Koblet til mottak</label>
                    <div class="control">
                      <router-link :to="{ name: 'Mottak', params: { guid: mottakUnitGuid } }" target='_blank'>Vis
                        prøve i mottak</router-link>
                      <button class="button is-small is-text ml-2" @click="unlink"> <i class="fa fa-unlink mr-1"></i>
                        Fjern kobling</button>
                    </div>
                  </div>
                  <div v-show="animal.mottakId == null">
                    <label class="label">Koble til mottak av prøver</label>
                    <div class="control">
                      <button class="button is-success is-small is-outlined" @click="showLinkModal = true">
                        <span class="icon is-small">
                          <i class="fa fa-link"></i>
                        </span>
                        <span>Koble</span>
                      </button>
                    </div>
                    <div class="help">
                      Knytt til mottaket på Grovlab for å sikrer sporbarhet fra ankomst til permanent lagring.
                    </div>
                  </div>
                </div>
              </div>
              </div>
            <div class="columns">
              <div class="column is-3">
                <div class="field">
                  
                  <label class="label">Dato registrert</label>
                  <flat-pickr v-model="animal.dateCreated"></flat-pickr>

                  <span class="help" v-show="animal.speciesGlobalTypeId === 1 ">Dødsdato for dyr felt under jakt og funndato/dødsdato for fallvilt. For merkede dyr er dette dato dyret ble merket (første gang).
        </span>
                </div>
              </div>
              <div class="column is-3" v-show="animal.speciesGlobalTypeId === 6 || animal.speciesGlobalTypeId === 7">
                <div class="field">
                  <label class="label">Dato funnet</label>
                  <flat-pickr v-model="animal.dateFound"></flat-pickr>
                </div>
              </div>
            </div>
            <div class="columns">
              <div class="column is-3">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <field-select label-text="Kjønn *" v-model="animal.sexId" v-bind:options="sex" placeholder="Kjønn"
                    :error="errors[0]" />
                </ValidationProvider>
              </div>
              <div class="column is-3">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <field-select label-text="Alder *" v-model="animal.ageId" v-bind:options="ages" placeholder="Alder"
                    :error="errors[0]" />
                    <span class="help" v-show="animal.ageId === null && animal.id > 0">Reg. fødselsår: {{ animal.birthYear }}</span>
                </ValidationProvider>
              </div>
             
              <div class="column is-3">
                <ValidationProvider rules="">
                  <field-input label-text="Vekt (gram)" v-model="animal.weight" inputType="number" />
                </ValidationProvider>
              </div>
            </div>
            <div class="columns">
              <div class="column is-3" v-show="animal.speciesGlobalTypeId === 1 || animal.speciesGlobalTypeId === 7">
                <field-select label-text="Kommune" v-bind:options="kommuner" v-model="animal.municipalityId" />
              </div>

              <div class="column is-3" v-show="animal.speciesGlobalTypeId === 1">
                <field-select label-text="Villreinområde" v-model="animal.wildRaindeerAreaId"
                  v-bind:options="raindeerAreas" />
              </div>

            </div>
            <div v-show="animal.speciesGlobalTypeId === 6">
              
              <div class="columns">
                <div class="column is-3">
                  <field-input label-text="Funnsted" v-model="animal.place" />
                </div>
              </div>
            </div>
          </fieldset>

          <sample-template v-if="animal.id === 0" ref="sampleTemplate" :speciesId="animal.speciesId" :globalTypeId="animal.speciesGlobalTypeId" :dateCreated="dateCreated" :individualCategoryId="animal.individualCategoryId"></sample-template>

          <div class="columns mt-5">
            <div class="column">
              <div class="is-pulled-right">
              <button type="submit" @click="save" class="button is-info">
                <span class="icon is-small">
                  <i class="fa fa-save"></i>
                </span>
                <span>Lagre</span>
              </button>

              <button type="button" class="button is-default ml-2" v-on:click="cancel">
                <span class="icon is-small">
                  <i class="fa fa-times"></i>
                </span>
                <span>Avbryt</span>
              </button>
            </div>
          </div>
        </div>
        </form>
      </ValidationObserver>
    </section>
    <link-mottak :speciesId="animal.speciesId" @showModal="showModal" @link="link"
      v-if="showLinkModal"></link-mottak>

  </div>
</template>

<script>
import api from "@/api/basicApi.js";
import LinkMottak from "./modals/LinkMottak.vue";
import SamplesTemplate from "./SamplesTemplate.vue";
export default {
  name: "CreateEditIndividual",
  data: function () {
    return {
      isFound: false,
      externalId: "",
      kommuner: [],
      raindeerAreas: [],
      species: [],
      sex: [],
      ages: [],
      categories: [],
      globalTypes: [],
      mottakUnitGuid: null,
      showLinkModal: false,
      checkedOutSamples: [],
      dateCreated: null,
      isCopy: false,
      multipleInternalIds: null
    };
  },
  props: {
    animal: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  components: {
    "link-mottak": LinkMottak,
    "sample-template": SamplesTemplate
  },
  mounted() {
    var self = this;
    api.fetchData({ url: "Individ/GetGlobalTypes/" }).then(res => {
      self.globalTypes = res.data;
    });

    if (this.animal.speciesGlobalTypeId > 0) {
      this.getSpecies()
      this.getDropdowns()
     }
  },
  methods: {
    updateSamples(samples) {
       this.samplesForSpecies = samples    
    },
    unlink() {
      this.animal.mottakId = null
    },
    showModal(value) {
      this.showLinkModal = value;
    },
    link(id) {
      let self = this
      api.fetchData({ url: "Mottak/GetSampleDataForMottakEnhet/" + id }).then(res => {
    
        self.animal.mottakId = res.data.mottakUnitId
        self.mottakUnitGuid = res.data.mottakUnitGuid

      })
      
    },
    edit() {
      this.isEdit = !this.isEdit
    },
    getDropdowns() {
      let self = this
      api.fetchData({ url: "Individ/GetDropdownsForGlobalType/" + this.animal.speciesGlobalTypeId }).then(res => {
        self.kommuner = res.data.kommuner;
        self.raindeerAreas = res.data.villreinomrader;
        self.sex = res.data.sex;
        self.ages = res.data.ages,
        self.categories = res.data.categories
      });
    },

    getSpecies() {
      let self = this
      api.fetchData({ url: "Individ/GetSpecies/" + this.animal.speciesGlobalTypeId }).then(res => {
        self.species = res.data
      });
    },

   
    getSpeciesTemplate() {
      let self = this
      if (!this.isCopy) {
        api.fetchData({ url: "Prove/GetTemplatesForSpecies/" + this.animal.speciesId }).then(res => {

          if (res.data) {
            self.animal.sexId = res.data.sexId
            self.animal.ageId = res.data.ageId
            self.animal.individualCategoryId = res.data.individualCategoryId
          } else {
            self.clearIndividualFields()
          }
        });
      }
      

    },

    clearIndividualFields() {
      if (!this.isCopy) {
        this.animal.sexId = null
        this.animal.ageId = null
        this.animal.individualCategoryId = null
        this.animal.municipalityId = null
        this.animal.wildRaindeerAreaId = null
      }
    },

    

    copyFromPreviousIndividual() {
      var self = this
      self.isCopy = true
      api.fetchData({ url: "Individ/GetInfoFromPrevious" }).then(res => {
        if (res.data) {      
          self.animal.speciesGlobalTypeId = res.data.speciesGlobalTypeId
          self.animal.speciesId = res.data.speciesId
          self.animal.sexId = res.data.sexId
          self.animal.ageId = res.data.ageId
          self.animal.individualCategoryId = res.data.individualCategoryId
          self.animal.municipalityId = res.data.municipalityId
          self.animal.wildRaindeerAreaId = res.data.wildRaindeerAreaId
          self.animal.mottakId = res.data.mottakId
        }
      })
      .finally(() => {
        self.$nextTick(() => {
          self.isCopy = false
        })
       
      })
      
    },
    
    
    save() {
      let self = this;
      self.$refs.form.validate().then(success => {
        if (!success) {
          return;
        } 
        else {
          if (self.animal.id == 0 && this.$refs.sampleTemplate.getSamples().length > 0) {
            
           
            if (self.multipleInternalIds != null) {
              var multipleIndividualIds = this.multipleInternalIds.trim().split('\n').map(function (item) {
                return item.trim();
              }); 
              self.animal.internalIds = multipleIndividualIds
            } else {
              self.animal.internalIds = null
            }
            var samplesToSave = this.$refs.sampleTemplate.getSamples();
            let individualsAndSamples = {
              individuals: self.animal,
              samples: samplesToSave
            };

            api.saveData({ url: "Individ/SaveIndividualsAndSamples", data: individualsAndSamples }).then(res => {
              self.$router.push({ name: "Individual", params: { individualId: res.data } })
              self.$emit("showIndividAndSamples");
            })
          }
          else {
            self.saveOrEditIndividual()
          }
        }
      });
    },

    saveOrEditIndividual() {
      let self = this
      api.saveData({ url: "Individ/SaveIndividual", data: self.animal }).then(res => {
        self.$router.push({ name: "Individual", params: { individualId: res.data } })
        self.$emit("showIndividAndSamples");
      })
    },

    cancel() {
      var individId = parseInt(this.$route.params.individualId);
      if (individId === 0) {
        this.$router.back();
      } else {
        this.$root.$emit("closeCreateEdit");
      }

    }
  },
  computed: {
    multipleIds () {
       return this.animal.speciesId == 10 && this.animal.id === 0 
    },
    getUrlMottak() {
      return "mottak/" + this.mottakUnitGuid
    }

  },
  watch: {
    'animal.speciesGlobalTypeId'(newValue) {
      if (newValue != null) {
        this.getSpecies()
        this.getDropdowns()
        this.samplesForSpecies = []
        
        if (this.isCopy) return;
        this.clearIndividualFields()
        this.animal.speciesId = null 
      }
    },
    'animal.speciesId'(newValue){
      if (newValue != null){
        this.getSpeciesTemplate()
      }
    },
    'animal.dateCreated'(newValue){
      if (newValue != null){
        this.dateCreated = newValue
      }
    },
    'animal.internalId'(newValue){
      if (newValue) {
        this.multipleInternalIds = null
      }
    }
  }
};
</script>
