const FishingTypeBaseEnum = Object.freeze([
    { id: 1, name: "Sportsfiske" },
    { id: 2, name: "Høstfiske" },
    { id: 4, name: "Annet" },
    { id: 6, name: "Lysfiske" },
    { id: 7, name: "Gytefisktelling" },
    { id: 8, name: "Ukjent" },
    { id: 9, name: "Elbåt-fiske" }
 ]);
  

//Brukes for fiskeskjell mottak
const FishingTypeEnum = Object.freeze([
    ...FishingTypeBaseEnum,
    { id: 3, name: "Sjølaksefiske" },    
    { id: 5, name: "Ungfiskundersøkelse" }
 ]);
  


//Brukes i fiskjeskjell arkiv
  const FishingTypeArchiveEnum = Object.freeze([
    ...FishingTypeBaseEnum,
    { id: 10, name: "Stamfiske" }

 ]);

 const FishingTypeFixed = Object.freeze([ 
   { id: 3, name: "Sjølaksefiske" },    
   { id: 5, name: "Ungfiskundersøkelse" }

]);

 const FishingTypeObjectEnum = Object.freeze({
  
  SPORTSFISKE: { id: 1, name: "Sportsfiske" },
   HØSTFISKE: { id: 2, name: "Høstfiske" },
   SJØLAKSEFISKE: { id: 3, name: "Sjølaksefiske" },    
   UNGFISKUNDERSØKELSE: { id: 5, name: "Ungfiskundersøkelse" },
   ANNET: { id: 4, name: "Annet" },
   LYSFISKE: { id: 6, name: "Lysfiske" },
   GYTEFISKTELLING: { id: 7, name: "Gytefisktelling" },
   UKJENT: { id: 8, name: "Ukjent" },
   ELBÅTFISKE: { id: 9, name: "Elbåt-fiske" },
   STAMFISKE: { id: 10, name: "Stamfiske" }
   });


  
  export {FishingTypeBaseEnum, FishingTypeEnum, FishingTypeArchiveEnum, FishingTypeObjectEnum, FishingTypeFixed}


  