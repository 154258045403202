<template>
    <div class="container">

            <h1 class="is-size-2">{{ vassdragNavn }}</h1>
            <div class="columns">
                <div class="column is-2">
                    <field-select label-text="Fangstår" v-model="sampleYear" v-bind:options="sampleYears" />
                </div>
            </div>
            <h3 class="is-size-4 mb-5">Mottak av prøver</h3>
            <table class="is-hidden-mobile table is-fullwidth is-bordered is-striped is-narrow">
                <thead class="has-background-info-light">
                    <tr>
                        <th>Fangstår</th>
                        <th>Dato</th>
                        <th>Type</th>
                        <th>Laks</th>
                        <th>Aure</th>
                        <th>Regnbue</th>
                        <th>Pukkel</th>
                        <th>Sjørøye</th>
                        <th>Annet</th>
                        <th v-if="fiskeskjellRolle"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(reg) in registreringer" :key="reg.id">
                        <td> {{ reg.sampleYear }}</td>
                        <td> {{ reg.dateReceived | formatDate }}</td>
                        <td> <span>{{ getFishingType(reg.fishingTypeId) }}</span> </td>
                        <td>{{ reg.laks }}</td>
                        <td>{{ reg.aure }}</td>
                        <td>{{ reg.regnbueørret }}</td>
                        <td>{{ reg.pukkellaks }}</td>
                        <td>{{ reg.røye }}</td>
                        <td>{{ reg.annet }}</td>
                        <td v-if="fiskeskjellRolle">
                            <router-link :to="{ path: '/mottakFiskeskjell/registrer/' + reg.id }">Vis</router-link>
                        </td>
                    </tr>
                </tbody>
            </table>

            <h3 class="is-size-4 mb-5">Opplegg av prøver</h3>

            <table class="is-hidden-mobile table is-fullwidth is-bordered is-striped is-narrow">
                <thead class="has-background-info-light">
                    <tr>
                        <th>Fangstår</th>
                        <th>Opplegg startet</th>
                        <th>Type</th>
                        <th>Ant prover (gammel)</th>
                        <th>Laks</th>
                        <th>Aure</th>
                        <th>Regnbue</th>
                        <th>Pukkel</th>
                        <th>Sjørøye</th>
                        <th>Annet</th>
                        <th>Lagt opp av</th>
                        <th>Opplegg ferdig</th>
                        <th>Status</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(reg) in opplegg" :key="reg.id + reg.vassdragId">
                        <td>{{ reg.sampleYear }}</td>
                        <td>{{ reg.oppleggStarted | formatDate }}</td>
                        <td>{{ getFishingType(reg.fishingTypeId) }}</td>
                        <td>{{ reg.numberOfSamples }}</td>
                        <td>{{reg.arter.laks}}</td>
                        <td>{{reg.arter.aure}}</td>
                        <td>{{reg.arter.regnbueørret}}</td>
                        <td>{{reg.arter.pukkellaks}}</td>
                        <td>{{reg.arter.røye}}</td>
                        <td>{{reg.arter.annet}}</td>
                        <td>{{ reg.oppleggByName }}</td>
                        <td>{{ reg.oppleggCompleted | formatDate }}</td>
                        <td>
                            <span v-if="reg.oppleggCompleted != null" class="icon has-text-success"><i
                                    class="is-success fas fa-check"></i></span>
                            <span v-else class="icon has-text-grey-lighter"><i
                                    class="has-text-grey-lighter fas fa-check"></i></span>
                        </td>
                        <td>
                            <a @click="editOpplegg(reg.id)">Endre</a>

                        </td>
                    </tr>
                </tbody>
            </table>
            <button type="button" class="button is-info mr-1" @click="createOpplegg">
                <span class="icon is-small">
                    <i class="fa fa-plus"></i>
                </span>
                <span>Registrer opplegg</span>
            </button>



        <div class="mt-5" v-show="registrerNy">
            <h3 class="is-size-4 mb-5">Registrer opplegg</h3>

                <ValidationObserver ref="form">
                    <div class="field">
                        <ValidationProvider rules="required|validYear" v-slot="{ errors }">
                            <field-input label-text="Fangstår" v-model="nyttOpplegg.sampleYear" name="Fangstår" />
                            <p class="help is-danger">{{ errors[0] }}</p>
                        </ValidationProvider>
                    </div>
                    <div class="field">
                        <ValidationProvider rules="required" v-slot="{ errors }">
                            <label class="label">Opplegg startet</label>
                            <flat-pickr v-model="nyttOpplegg.oppleggStarted"></flat-pickr>
                            <p class="help is-danger">{{ errors[0] }}</p>
                        </ValidationProvider>
                    </div>

                    <div class="field">
                        <ValidationProvider rules="required" v-slot="{ errors }">
                            <field-select label-text="Type fiske" v-model="nyttOpplegg.fishingTypeId"
                                v-bind:options="fishingTypes" />
                            <p class="help is-danger">{{ errors[0] }}</p>
                        </ValidationProvider>
                    </div>

                    <div class="field">
                        <AntallArter :model="nyttOpplegg.arter" :isDisabled="false"></AntallArter>
                        <!-- <VelgArter :model="nyttOpplegg.species" @update:model="updateSpecies"></VelgArter> -->
                    </div>


                    <div class="field">
                        <ValidationProvider rules="required" v-slot="{ errors }">
                            <field-select label-text="Lagt opp av" v-model="nyttOpplegg.oppleggById"
                                v-bind:options="oppleggere" />
                            <p class="help is-danger">{{ errors[0] }}</p>
                        </ValidationProvider>
                    </div>

                    <!-- <div class="field">
                        <ValidationProvider rules="required" v-slot="{ errors }">
                            <field-input label-text="Antall prøver" v-model="nyttOpplegg.numberOfSamples"
                                name="Antall prøver">
                            </field-input>
                            <p class="help is-danger">{{ errors[0] }}</p>
                        </ValidationProvider>

                    </div> -->

                    <div class="field">

                        <label class="label">Opplegg ferdig</label>
                        <flat-pickr v-model="nyttOpplegg.oppleggCompleted"></flat-pickr>

                    </div>

                    <div class="field is-grouped mb-3">
                        <div class="control">
                            <button class="button is-info" @click="registrerOpplegg()"><span class="icon is-small">
                                    <i class="fa fa-save"></i>
                                </span>
                                <span>Registrer</span></button>
                        </div>
                        <div class="control">
                            <button class="button is-default" @click="registrerNy = false">
                                <span class="icon is-small">
                                    <i class="fa fa-times"></i>
                                </span>
                                <span>Avbryt</span>
                            </button>
                        </div>
                        <div class="control" v-if="nyttOpplegg.id > 0">
                            <button class="button is-danger is-outlined" @click="slettOpplegg()">
                                <span class="icon is-small">
                                    <i class="fa fa-trash"></i>
                                </span>
                                <span>Slett</span></button>
                        </div>
                    </div>
                </ValidationObserver>


        </div>


        <button class="button is-default" @click="$router.push({ name: 'aktivitet' })">Tilbake</button>
        <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
    </div>
</template>

<script>
import api from "@/api/basicApi.js";
import { FishingTypeEnum } from "@/enums/FishingTypeEnum";
import FieldInput from '../../globals/field-input.vue';
import ConfirmDialogue from '@/components/modals/ConfirmDialogue.vue';
import AntallArter from '@/components/grouped/AntallArter.vue';

export default {

    data: function () {
        return {
            vassdragNavn: '',
            registreringer: [],
            currentView: '',
            editableRowIndex: null,
            nyttOpplegg: {
                vassdragId: this.$route.params.vassdragId,
                species: '',
                arter: {
                    laks: 0,
                    aure: 0,
                    røye: 0,
                    pukkellaks: 0,
                    regnbueørret: 0,
                    annet: 0,
                    annetTekst: ''
                }
            },
            oppleggere: [],
            registrerNy: false,
            opplegg: [],
            fishingTypes: FishingTypeEnum,
            user: JSON.parse(localStorage.getItem("user")),
            sampleYears: [],
            sampleYear: null

        }
    },
    components: { FieldInput, "confirm-dialogue": ConfirmDialogue, AntallArter},
    mounted() {
        let self = this;
        self.getPrøverOgOpplegg();
        api.fetchData({ url: "MottakFiskeskjellAktivitet/GetUsers" })
            .then(res => {
                self.oppleggere = res.data

            });
        api.fetchData({ url: "MottakFiskeskjellAktivitet/GetSampleYears" })
            .then(res => {
                self.sampleYears = res.data.sampleYears
            });

    },
    computed: {
        fiskeskjellRolle() {
            return this.user != null && (this.user.accountRoles.includes(1) || this.user.accountRoles.includes(5));
        }
    },
    methods: {
        getPrøverOgOpplegg() {
            let self = this;
            let vassdragId = self.$route.params.vassdragId
            let fylkeId = self.$route.params.fylkeId
            let funnsted = self.$route.params.funnsted
            if (this.sampleYear == null) {
                this.sampleYear = self.$route.params.sampleYear
            }
            
            api.fetchData({ url: `MottakFiskeskjellAktivitet/GetFiskeskjellMottakSamplesForVassdrag/${vassdragId}/${fylkeId}/${funnsted}/${this.sampleYear}` })
                .then(res => {
                    self.vassdragNavn = res.data.vassdragName
                    self.registreringer = res.data.prøverForVassdragÅr
                });

            api.fetchData({ url: `MottakFiskeskjellAktivitet/GetOppleggForVassdrag/${vassdragId}/${fylkeId}/${funnsted}/${this.sampleYear}` })
                .then(res => {
                    self.opplegg = res.data
                });
        },
        createOpplegg() {
            this.registrerNy = true
            this.nyttOpplegg = {
                sampleYear: this.sampleYear,
                vassdragId: this.$route.params.vassdragId,
                funnsted: this.$route.params.funnsted,
                fylkeId: this.$route.params.fylkeId,
                species: '',
                arter: {
                    laks: 0,
                    aure: 0,
                    røye: 0,
                    pukkellaks: 0,
                    regnbueørret: 0,
                    annet: 0,
                    annetTekst: ''
                }
            }
        },

        registrerOpplegg() {
            let self = this
            self.$refs.form.validate().then(success => {
                if (!success) {
                    return;
                }
                else {
                    api.saveData({ url: "MottakFiskeskjellAktivitet/SaveOppleggForVassdrag", data: self.nyttOpplegg })
                        .then(res => {
                            self.registrerNy = false;
                            const index = self.opplegg.findIndex(a => a.id === res.data.id)
                            if (index === -1) {
                                self.opplegg.push(res.data)
                            }
                            else {
                                self.opplegg[index] = res.data
                            }
                        })
                }
            })
        },
        editOpplegg(id) {
            var oppleggToEdit = this.opplegg.find(o => o.id === id);
            const copiedOpplegg = { ...oppleggToEdit };
            this.nyttOpplegg = copiedOpplegg;
            this.registrerNy = true;
        },
        slettOpplegg() {
            let self = this
            this.$refs.confirmDialogue.show({
                title: 'Slett opplegg',
                message: 'Er du sikker på at du vil slette opplegget?',
                okButton: 'Slett',
            }).then((result) => {
                if (result) {
                    api.deleteData({ url: "MottakFiskeskjellAktivitet/SlettOpplegg/" + self.nyttOpplegg.id }).then(res => {
                        if (res) {
                            self.registrerNy = false;
                            const index = self.opplegg.findIndex(a => a.id === self.nyttOpplegg.id)
                            self.opplegg.splice(index, 1)
                        }
                    })
                }
            })
        },

        getFishingType(id) {
            return this.fishingTypes.find(x => x.id == id).name;
        },
        updateSpecies(newVal) {
            this.nyttOpplegg.species = newVal
        }

    },
    watch: {
        'sampleYear'(newVal) {
            if (newVal != null) {
                this.registrerNy = false
                this.getPrøverOgOpplegg()
            }

        }
    }
};
</script>
<style scoped>
.table td {
    vertical-align: middle;
}

.center {
    margin-top: 35px;
}
</style>