<template>
    <div>
        <!-- VUE JS SmartTable https://tochoromero.github.io/vuejs-smart-table/#installation-->

        <div class="notification has-text-danger" v-show="hasWarning">
            <p><span class="icon">
                    <i class="fas fa-exclamation-triangle"></i>
                </span> Det er prøver i mottaket som har ligget over tidsgrensen.  <a @click='getExpiredUnits'>Vis prøver</a></p>
        </div>

        <div class="columns">

            <div class="column is-8">
                <div class="has-text-info" v-show="manyHits">
                    Listen viser de
                    <strong>{{ samplesReturned }} nyeste</strong> prøvene av totalt
                    <strong>{{ samplesTotal }}</strong> prøver. 
                    <a @click="toggleVisAlleProver" v-show="!filters.visAlleProver">Vis alle prøver</a>
                    <a @click="toggleVisAlleProver" v-show="filters.visAlleProver">Vis 100 nyeste</a>
                </div>
            </div>
            <div class="column">
                <div class="control">
                    <button :disabled="!isBulkEdit" @click="showBulkEditDialogue"
                        class="button is-small is-outlined is-info is-pulled-right ml-2">
                        <span class="icon is-small">
                            <i class="fas fa-pen"></i>
                        </span>
                        <span>Endre flere</span>
                    </button>
                    <button @click="clearAllFilters()" class="button is-small is-pulled-right is-info is-outlined ml-2">
                        <span class="icon is-small">
                            <i class="fas fa-filter"></i>
                        </span>
                        <span>Fjern filter</span>
                    </button>
                    <label class="checkbox is-pulled-right mt-2 is-size-7" >
                    <input type="checkbox" class="is-small" v-model="filters.showCheckedOutItems" :value=!filters.showCheckedOutItems>
                        Utsjekkede
                    </label>

                </div>
               
            </div>
            <div class="is-clearfix"></div>
        </div>

        <v-table :data="enheter" class="table is-fullwidth is-bordered is-striped is-narrow">
            <thead slot="head" class="has-background-info-light">
                <v-th sort-key="checkinDate" defaultSort="desc">Dato</v-th>
                <th>
                    <span>Art
                        <a @click="openModal('speciesId')" class="is-pulled-right" v-bind:class="{
                            'has-text-success': filterActive('speciesId'),
                            'has-text-grey': !filterActive('speciesId')
                        }"><i class="fas fa-filter"></i></a></span>
                    <filter-modal v-bind:options="dropdowns.species" @input="input" v-show="showSpecies"
                        filterName="speciesId" @close="close" ref="speciesFilter"></filter-modal>
                </th>
                <th><span>Prøvetype
                        <a @click="openModal('sampleMaterialId')" class="is-pulled-right" v-bind:class="{
                            'has-text-success': filterActive('sampleMaterialId'),
                            'has-text-grey': !filterActive('sampleMaterialId')
                        }"><i class="fas fa-filter"></i></a></span>
                    <filter-modal v-bind:options="dropdowns.sampleMaterials" @input="input" v-show="showSampleMaterial"
                        filterName="sampleMaterialId" @close="close" ref="sampleMaterialFilter">
                    </filter-modal>
                </th>
                <th>
                    <span>Ansvarlig
                        <a @click="openModal('projectLeaderId')" class="is-pulled-right" v-bind:class="{
                            'has-text-success': filterActive('projectLeaderId'),
                            'has-text-grey': !filterActive('projectLeaderId')
                        }"><i class="fas fa-filter"></i></a></span>
                    <filter-modal v-bind:options="dropdowns.projectLeaders" @input="input" v-show="showProjectLeader"
                        filterName="projectLeaderId" @close="close" ref="projectLeaderFilter"></filter-modal>
                </th>
                <th>
                    <span>Lagringssted
                        <a @click="openModal('storageLocationId')" class="is-pulled-right" v-bind:class="{
                            'has-text-success': filterActive('storageLocationId'),
                            'has-text-grey': !filterActive('storageLocationId')
                        }"><i class="fas fa-filter"></i></a></span>
                    <filter-modal v-bind:options="dropdowns.storageLocations" @input="input" v-show="showStorageLocation"
                        filterName="storageLocationId" @close="close" ref="storageLocationFilter"></filter-modal>
                </th>
                <v-th sort-key="createdBy" defaultSort="asc">Registrert av</v-th>

                <th><input type="checkbox" v-model="checkAll"> Velg alle </th>
                <th></th>
            </thead>
            <tbody slot="body" slot-scope="{displayData}">
                <tr v-for="row in displayData" :key="row.id">
                    <td>{{ row.checkinDate | formatDate }}
                        <span v-show="row.expired"
                            class="icon has-text-danger">
                            <i class="fas fa-exclamation-triangle"></i>
                        </span>
                    </td>
                    <td>{{ row.speciesName }}</td>
                    <td>{{ row.sampleMaterialName }}</td>
                    <td>{{ row.projectLeader }}</td>
                    <td>{{ row.storageLocationName }}</td>

                    <td>{{ row.createdBy }}</td>
                    <td> <label class="checkbox">
                            <input type="checkbox" @click="toggleValgteEnheter(row.id)" v-model="valgteEnheter"
                                :value="row.id">
                        </label>
                    </td>
                    <td><a @click="showEnhet(row.guid)">Vis</a></td>
                </tr>
            </tbody>
        </v-table>

        <table class="is-hidden-desktop table is-fullwidth ">
            <thead class="has-background-info-light">
                <tr>
                    <th>Innhold mottaksfryser</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="enhet in enheter" :key="enhet.id">
                    <td>
                        <div>
                            <span class="has-text-weight-bold">{{ enhet.checkinDate | formatDate }}</span> <span
                                class="ml-2">{{ enhet.speciesName }} {{ enhet.sampleMaterialName }} </span>
                            <a class="is-pulled-right" @click="showEnhet(enhet.guid)"> <span class="icon is-small is-right"
                                    style="color: #3298dc">
                                    <i class="fas  fa-chevron-right"></i>
                                </span></a>
                        </div>
                        <div> <span class="is-italic">{{ enhet.projectLeader }}</span></div>
                    </td>
                </tr>
            </tbody>
        </table>
        <bulk-edit ref="bulkEditDialog" @valuesToUpdate="updateForSelected"></bulk-edit>
    </div>
</template>
  
<script>

import api from "@/api/basicApi.js";
import FilterModal from "@/components/modals/FilterModal.vue";
import BulkEditMottakSamples from '../modals/BulkEditMottakSamples.vue';

export default {

    data: function () {
        return {
            enheter: [],
            warningOldSamples: false,
            dropdowns: [],
            samplesTotal: 0,
            samplesReturned: 0,
            filters: {
                speciesId: null,
                storageLocationId: null,
                sampleMaterialId: null,
                projectLeaderId: null,
                showCheckedOutItems: false,
                showExpiredUnits: false,
                visAlleProver: false
            },
            showSpecies: false,
            showStorageLocation: false,
            showSampleMaterial: false,
            showProjectLeader: false,
            valgteEnheter: []
            
        }
    },
    components: {
        "filter-modal": FilterModal,
        "bulk-edit": BulkEditMottakSamples
    },
    mounted() {
        let self = this
        this.getUnits(this.filters)

        api.fetchData({ url: "Mottak/GetDropdownsForFilters" }).then(res => {
            self.dropdowns = res.data;
        })
    },
    computed: {
        hasWarning() {
            return this.warningOldSamples;
        },
        manyHits() {
            return this.samplesReturned >= 100;
        },
        isBulkEdit() {
            return this.valgteEnheter.length > 0
        },
        checkAll: {
            get: function () {
                return this.enheter ? this.valgteEnheter.length == this.enheter.length : false;
            },
            set: function (value) {
                var valgteEnheter = [];
                if (value) {
                    this.enheter.forEach(function (enhet) {
                        valgteEnheter.push(enhet.id);
                    });
                }
                this.valgteEnheter = valgteEnheter;
            }
        }
        
    },
    watch: {
        filters: {
            handler(newFilter) {
                this.getUnits(newFilter)
            },
            deep: true
        }
    },
    methods: {
        getUnits(filters) {
            let self = this;
            api.postData({ url: "Mottak/GetUnits/", data: filters }).then(res => {
                self.enheter = res.data.mottakUnitsList;
                self.samplesTotal = res.data.mottakUnitsTotal;
                self.samplesReturned = res.data.samplesReturned,
                self.warningOldSamples = res.data.hasExpiredUnits
            });
        },
        showEnhet(guid) {
            this.$router.push({ name: 'Mottak', params: { guid: guid } })
            this.$emit("showUnitByGuid", guid);
        },
        getExpiredUnits() {
            this.filters.showExpiredUnits = true
        },

        timeLimitExceeded(date, checkoutDate, sampleMaterialName, storageLocationName) {
            
            
            if (checkoutDate !== null) return false

            var sampleDate = new Date(date);
            let twoMonthsAgo = new Date();
            twoMonthsAgo.setMonth(twoMonthsAgo.getMonth() - 2);

            let threeMonthsAgo = new Date()
            threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);

            if (storageLocationName == "Intern Trondheim") {
                if (sampleMaterialName === 'Skrott' && +twoMonthsAgo > +sampleDate) {
                    this.warningOldSamples = true
                    return true;
                }
                if (+threeMonthsAgo > +sampleDate) {
                    this.warningOldSamples = true
                    return true
                }
            }


            return false
        },
        filterActive(value) {
            if (this.filters[value] !== "" && this.filters[value] !== null) {
                return true;
            } else {
                return false;
            }
        },
        openModal(filterName) {
            this.closeModals();
            if (filterName == "speciesId") {
                this.showSpecies = true;
            }
            if (filterName == "storageLocationId") {
                this.showStorageLocation = true
            }
            if (filterName == "sampleMaterialId") {
                this.showSampleMaterial = true;
            }
            if (filterName == "projectLeaderId") {
                this.showProjectLeader = true;
            }

        },
        closeModals() {

            this.showSpecies = false;
            this.showStorageLocation = false;
            this.showSampleMaterial = false;
            this.showProjectLeader = false;

        },
        close() {
            this.closeModals();
        },
        input(filter, value) {
            this.closeModals();
            this.filters[filter] = value;
        },
        clearAllFilters() {
            this.closeModals();
            this.$refs.speciesFilter.clearSelected();
            this.$refs.storageLocationFilter.clearSelected();
            this.$refs.sampleMaterialFilter.clearSelected();
            this.$refs.projectLeaderFilter.clearSelected();
            this.filters["speciesId"] = null;
            this.filters["storageLocationId"] = null;
            this.filters["sampleMaterialId"] = null;
            this.filters["projectLeaderId"] = null;
            this.filters["showCheckedOutItems"] = false;
            this.filters["showExpiredUnits"] = false;
        },
        showBulkEditDialogue() {
            this.$refs.bulkEditDialog.show();
        },
        updateForSelected(valuesToUpdate) {
            let self = this
            let bulkEditDto = {
                UnitsToEdit: this.valgteEnheter,
                ValuesToUpdate: valuesToUpdate
            }
            api.saveData({ url: "Mottak/BulkEdit/", data: bulkEditDto }).then(function (){
                self.getUnits(self.filters)
                // this.$nextTick(() => {
                //     res.data.forEach(unit => {
                //     const index = self.enheter.findIndex(e => e.id === unit.id)
                //     if (index > -1) {
                //         unit.shouldAnimate = true
                //         this.$set(self.enheter, index, unit)
                //     } 
                // });
            //})

                self.valgteEnheter = []
            })

        },
        toggleValgteEnheter(id) {
            const index = this.valgteEnheter.indexOf(id);
            if (index === -1) {
                this.valgteEnheter.push(id);
            } else {
                this.valgteEnheter.splice(index, 1);
            }
        },
        toggleVisAlleProver() {
            this.filters.visAlleProver = !this.filters.visAlleProver
        }
    }
};
</script>
<style scoped>
.filter-card {
    position: absolute;
    z-index: 100;
}

@keyframes fade-out {
    0% {
        background-color: #7A9A01;
    }

    100% {
        background-color: transparent;
    }
}

.fade-in {
    animation-timing-function: ease-in;
    animation-name: fade-out;
    animation-duration: 3s;
}
</style>
  