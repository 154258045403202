<template>
  <div id="app">
    <nav class="navbar is-info" role="navigation" aria-label="main navigation">
      <div class="navbar-brand">
        <router-link class="navbar-item is-size-5" to="/"> <img src="NINA_logo_emblem.png" > <span class="ml-2"> Biobase</span></router-link>

        <a role="button" class="navbar-burger burger" aria-label="menu" aria-expanded="isActive"
          :class="{ 'is-active': isActive }" data-target="navbarBasicExample" @click="isActive = !isActive">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div id="navbarBasicExample" :class="{ 'is-active': isActive }" class="navbar-menu">
        <div class="navbar-start">
          <router-link v-if="isLoggedIn && hjorteviltRole" v-show="hjorteviltRole" class="navbar-item" to="/individual" >Individ</router-link>
          <router-link v-if="isLoggedIn && (lesebrukerRole || hjorteviltRole)" class="navbar-item" to="/search">Søk i prøver</router-link>
          <router-link v-if="mottaksfrysRole && isLoggedIn" class="navbar-item" to="/mottak" >Mottaksfryser</router-link>
          <router-link  v-if="fiskeskjellRole && isLoggedIn" class="navbar-item" to="/mottakFiskeskjell">Mottak fiskeskjell</router-link>
          <!-- <router-link  v-if="fiskeskjellRole && isLoggedIn" class="navbar-item" to="/units">Skjellarkiv</router-link> -->
        </div>

        <div class="navbar-end">
          <a class="navbar-item" v-if="isLoggedIn">
            <span class="icon is-small">
              <i class="fas fa-user"></i>
            </span>
            <span class="ml-1">{{ userName }}</span>
          </a>

          <a class="navbar-item" @click="logout" v-show="isLoggedIn">
            Logg ut
          </a>
        </div>
      </div>
    </nav>
    <div class="loader-wrapper" id="loader">
      <div class="loader is-loading is-primary"></div>
    </div>
    <router-view />
  </div>
</template>


<script>

import api from "@/api/basicApi.js";
import { EventBus } from "@/event-bus.js";
export default {
  data: function () {
    return {
      isActive: false,
      loggedIn: false,
      user: JSON.parse(localStorage.getItem("user"))
    };
  },
  methods: {
    logout() {
      let self = this;
      api
        .logout()
        .then(function () {
          self.$router.push({ path: "/login" });
          self.loggedIn = false;
        })
        .catch(function () {
          self.$router.push({ path: "/login" });
          self.loggedIn = false;
        });
    },
  },
  mounted() {
    let self = this;
    const isLoggedIn = localStorage.getItem("user");
    if (!isLoggedIn) {
      self.loggedIn = false;
    } else {
      self.loggedIn = true;
    }
    EventBus.$on("loggedIn", function () {
      self.loggedIn = true;
      self.user = JSON.parse(localStorage.getItem("user"));
    });
    EventBus.$on("loggedOut", function () {
      self.loggedIn = false;
      self.user = null;
    });
  },
  computed: {
    isLoggedIn() {
      return this.loggedIn;
    },
    userName() {
      if (this.user != null) {
        return this.user.firstName;
      } else {
        return "";
      }
    },

    //Ved fiksing av menypunkter som forsvinner, accountRoles kan være ikke satt. Finn ut hvordan det skjer.
    adminRole() {
      return this.user != null && this.user.accountRoles && this.user.accountRoles.includes(1)
    },
    fiskeskjellRole() {
      return this.user != null && (this.adminRole || this.user.accountRoles && (this.user.accountRoles.includes(5) || this.user.accountRoles.includes(6)));
    },
    hjorteviltRole() {
      return this.user != null && (this.adminRole || this.user.accountRoles && this.user.accountRoles.includes(2))
    },
    mottaksfrysRole() {
      return this.user != null && (this.adminRole || this.user.accountRoles && this.user.accountRoles.includes(3))
    },
    lesebrukerRole() {
      return this.user != null && (this.adminRole || this.user.accountRoles && this.user.accountRoles.includes(4))
    }
  }
};
</script>

<style>
.navbar-brand .navbar-item img {
    max-height: 2.0rem;
}

.loader-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #fff;
  opacity: 0;
  z-index: -1;
  transition: opacity 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
}

.loader-wrapper .loader {
  height: 80px;
  width: 80px;
}

.loader-wrapper.is-active {
  opacity: 0.7;
  z-index: 1;
}

.is-loading {
  position: relative;
}
</style>
