<template>
    <div class="container">
        <div class="columns">
            <div class="column is-1">
                <field-select label-text="Fangstår" v-model="sampleYear" v-bind:options="sampleYears" />
            </div>
            <div class="column is-8">
                <FylkeVassdrag :model="fylkeVassdrag"></FylkeVassdrag>
            </div>

            <div class="column is-2">
                <field-select label-text="Type fiske" v-model="fishingTypeId" v-bind:options="fishingTypes" />
            </div>

            <div class="column is-1">
                <label class="label">Eksporter</label>
                <div class="dropdown" :class="{ 'is-active': dropdownExpanded }"
                    @click="dropdownExpanded = !dropdownExpanded">
                    <div class="dropdown-trigger">
                        <button class="button" aria-haspopup="true" aria-controls="dropdown-menu">
                            <span class="icon is-small">
                                <i class="fas fa-download" aria-hidden="true"></i>
                            </span>
                            <span class="icon is-small">
                                <i class="fas fa-angle-down" aria-hidden="true"></i>
                            </span>
                        </button>
                    </div>
                    <div class="dropdown-menu" id="dropdown-menu" role="menu">
                        <div class="dropdown-content">
                            <a @click="downloadReport(true)" class="dropdown-item">
                                Betalingsoversikt med kontonummer
                            </a>
                            <a @click="downloadReport()" class="dropdown-item">
                                Betalingsoversikt alle
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="table-wrapper">
            <table class="table is-fullwidth is-bordered is-striped is-narrow is-scrollable">
                <thead class="has-background-info-light">
                    <tr>
                        <th><abbr title="Fangstår">År</abbr></th>
                        <th>Fylke</th>
                        <th>Vassdrag</th>
                        <th>Type</th>
                        <th><abbr title="Antall prøver">Ant prø</abbr></th>
                        <th><abbr title="Laks">LA</abbr></th>
                        <th><abbr title="Aure">AU</abbr></th>
                        <th><abbr title="Røye">RØ</abbr></th>
                        <th><abbr title="Pukkellaks">PU</abbr></th>
                        <th><abbr title="Regnbueørret">RE</abbr></th>
                        <th><abbr title="Annet">AN</abbr></th>
                        <th>Innsender</th>
                        <th><abbr title="Antall prøver utbetalt">Ant utb</abbr></th>
                        <th>Beløp</th>
                        <th>Kontonr</th>
                        <th>Ok</th>
                        <th>Dato utbetalt</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(reg, index) in filteredList" :key="index">
                        <td>{{ reg.sampleYear }}</td>
                        <td> {{ reg.fylkeNavn }}</td>
                        <td>{{ reg.vassdragNavn }}</td>
                        <td>{{ getFishingType(reg.fishingTypeId) }}</td>
                        <td>{{ reg.totalSamples }}</td>
                        <td>{{ reg.laks }}</td>
                        <td>{{ reg.aure }}</td>
                        <td>{{ reg.røye }}</td>
                        <td>{{ reg.pukkellaks }}</td>
                        <td>{{ reg.regnbueørret }}</td>
                        <td>{{ reg.annet }}</td>
                        <td>{{ reg.innsender }}</td>
                        <td> {{ reg.antProverUtbetalt }}</td>
                        <td> {{ reg.belop }}</td>
                        <td>{{ reg.kontonummer }}</td>
                        <td class="has-text-centered"><span v-show="reg.godkjent" class="icon has-text-success">
                                <i class="fas fa-lg fa-check"></i>
                            </span>
                        </td>
                        <td>{{ reg.datoUtbetalt | formatDate }}</td>
                        <td><button :disabled="false" @click="showFormModal(index)"
                                class="button is-small is-outlined is-info is-pulled-right ml-2">
                                <span class="icon is-small">
                                    <i class="fas fa-pen"></i>
                                </span>
                            </button></td>

                    </tr>
                </tbody>
            </table>
        </div>
        <!-- TODO: Endres som Units og Unit-->
        <form-modal id="modalBoks" ref="formModal" title="Registrer betaling" @submit="handleSubmit">
            <component :is="betalingForm" :model="betaling" ref="formComponent"></component>
        </form-modal>

    </div>
</template>

<script>

import api from "@/api/basicApi.js";
import FylkeVassdrag from '../../grouped/FylkeVassdrag.vue';
import { FishingTypeEnum } from "@/enums/FishingTypeEnum";
import { PROVEBASE_CONFIG } from "@/../config.js";
import FormModal from '@/components/modals/FormModal.vue';
import RegistrerBetaling from './RegistrerBetaling.vue'

export default {

    data: function () {
        return {
            registreringer: [],
            fylkeVassdrag: {
                fylkeId: '',
                vassdragId: null
            },
            fishingTypes: FishingTypeEnum,
            selectedSpecies: [],
            fishingTypeId: null,
            sampleYears: [],
            sampleYear: null,
            dropdownExpanded: false,
            betalingForm: RegistrerBetaling,
            currentIndex: null,
            betaling: {}
        }
    },
    components: {
        FylkeVassdrag, "form-modal": FormModal, RegistrerBetaling
    },
    mounted() {
        let self = this;
        self.getVassdragOversiktBetaling()
        api.fetchData({ url: "MottakFiskeskjellAktivitet/GetSampleYears" })
            .then(res => {
                self.sampleYears = res.data.sampleYears
                self.sampleYear = res.data.defaultYear
            });

    },
    computed: {
        filteredList() {
            if (this.fylkeVassdrag.fylkeId != '' || this.fishingTypeId > 0 || this.fylkeVassdrag.vassdragId != null || this.selectedSpecies.length > 0) {
                let filterFunction = () => true;

                if (this.fylkeVassdrag.fylkeId != '') {
                    const prevFilter = filterFunction;
                    filterFunction = item => prevFilter(item) && item.fylkeId === this.fylkeVassdrag.fylkeId;
                }
                if (this.fishingTypeId > 0) {
                    const prevFilter = filterFunction;
                    filterFunction = item => prevFilter(item) && item.fishingTypeId === this.fishingTypeId;
                }
                if (this.fylkeVassdrag.vassdragId != null) {
                    const prevFilter = filterFunction;
                    filterFunction = item => prevFilter(item) && item.vassdragId === this.fylkeVassdrag.vassdragId;
                }
                return this.registreringer.filter(filterFunction);
            } else {
                return this.registreringer;
            }
        }
    },

    methods: {
        showFormModal(index) {
            this.currentIndex = index
            var rowInfo = this.filteredList[this.currentIndex];
            let self = this
            let criterias = {
                vassdragId: rowInfo.vassdragId,
                sampleYear: rowInfo.sampleYear,
                fishingTypeId: rowInfo.fishingTypeId,
                innsenderId: rowInfo.innsenderId,
                betalingId: rowInfo.id,
                funnsted: rowInfo.vassdragNavn
            }
            api.postData({ url: "MottakFiskeskjellOversikt/GetBetalingInfo", data: criterias }).then(res => {
                self.betaling = res.data
            })


            this.$refs.formModal.show();
        },
        handleSubmit() {
            let self = this;
            const formComponent = self.$refs.formComponent;

            formComponent.save(betaling => {
                var rowToUpdate = self.filteredList[self.currentIndex];
                rowToUpdate.antProverUtbetalt = betaling.antProverUtbetalt;
                rowToUpdate.datoUtbetalt = betaling.datoUtbetalt;
                rowToUpdate.id = betaling.id
                rowToUpdate.belop = betaling.belop;
                rowToUpdate.godkjent = betaling.godkjent;
                rowToUpdate.kontonummer = betaling.innsenderKontonummer;
                this.$refs.formModal.close();
            })
        },

        getFishingType(id) {
            return this.fishingTypes.find(x => x.id == id).name;
        },
        getVassdragOversiktBetaling() {
            let self = this
            if (self.sampleYear != null) {
                api.fetchData({ url: "MottakFiskeskjellOversikt/GetVassdragOversiktAggregertBetaling/" + self.sampleYear }).then(res => {
                    self.registreringer = res.data
                });
            }
        },
        downloadReport(medKontonr) {
            api.fetchData({ url: "MottakFiskeskjellOversikt/GetEncryptedToken" }).then(res => {
                let tokenFromServer = res.data;
                let queryString = "encryptedToken=" + tokenFromServer;
                queryString += "&sampleYear=" + this.sampleYear + "&countyId=" + this.fylkeVassdrag.fylkeId
                    + "&vassdragId=" + this.fylkeVassdrag.vassdragId + "&fishingTypeId=" + this.fishingTypeId + "&withAccounts=" + medKontonr

                if (this.sampleYear != null) {
                    window.location.href = `${PROVEBASE_CONFIG.API_URL}/` + "MottakFiskeskjellOversikt/BetalingReport?" + queryString;
                }
            })
        }
    },
    watch: {
        'sampleYear'(newVal) {
            if (newVal != null) {
                this.getVassdragOversiktBetaling()
            }

        }
    }
};
</script>

<style lang="css" scoped>

.table-wrapper {
  overflow-x: auto;
  font-size: 15px;
}

</style>