<template>
    <ValidationObserver ref="form">


        <div class="columns">
            <div class="column">
                <div class="field">
                    <field-input label-text="Antall prøver utbetalt" inputType="number"
                        v-model="model.antProverUtbetalt" name="Antall prøver utbetalt" />
                </div>
            </div>
            <div class="column">
                <div class="field">
                    <field-input label-text="Stykkpris" inputType="number" :value="getStykkpris"
                        name="Antall prøver utbetalt" />
                </div>
            </div>
            <div class="column">
                <div class="field">
                    <field-input label-text="Porto" inputType="number" v-model="model.porto" name="Porto" />
                </div>
            </div>
        </div>

        <div class="columns">
            <div class="column is-4">
                <div class="field">
                    <label class="label">Utregnet beløp</label>
                    <input type="text" class="input" :value=calculatedTotal disabled />
                </div>
            </div>

            <div class="column is-4">
                <div class="field">
                    <field-input label-text="Beløp" v-model="model.belop" inputType="number" name="Beløp" />
                    <a @click="setUtregnetBeløp()">Bruk utregnet beløp</a>

                </div>
            </div>

        </div>

        <div class="field">
            <ValidationProvider rules="">
                <input type="checkbox" class="is-inline mr-2" v-model="model.godkjent" />
                <label class="label is-inline-block">Opplysninger godkjent</label>
                <div class="help"> Opplysninger sjekket og godkjent, klar til utbetaling </div>
            </ValidationProvider>
        </div>

        <div>
            <div class="notification is- mt-5">
                <button v-show="!isEdit" @click="isEdit = true" class="button is-primary is-outlined is-small is-pulled-right">
                    <span class="icon is-small">
                        <i class="fas fa-edit"></i>
                    </span>
                    <span>Endre</span>
                </button>
                <button v-show="isEdit" @click="isEdit = false" class="button is-outlined is-small is-pulled-right">
                    <span class="icon is-small">
                        <i class="fas fa-times"></i>
                    </span>
                    <span>Avbryt</span>
                </button>
                <button v-show="isEdit" @click="saveUserInfo" class="mr-1 button is-primary is-outlined is-small is-pulled-right">
                    <span class="icon is-small">
                        <i class="fas fa-save"></i>
                    </span>
                    <span>Lagre</span>
                </button>
                <div class="is-clearfix"></div>
                <div class="betaling-info mt-3">                   
                    <div class="columns">
                        <div class="column is-4"> 
                            <label class="label">Kontonummer</label>
                        </div>
                        <div class="column">
                            <template v-if="isEdit"><field-input v-model="model.innsenderKontonummer" name="Kontonummer" /></template>
                            <template v-if="!isEdit">{{ model.innsenderKontonummer }}</template>
                        </div>
                    </div>

                    <div class="columns">
                        <div class="column is-4">
                            <label class="label">Porto</label>
                        </div>
                        <div class="column">
                           <template v-if="isEdit"><field-input v-model="model.porto" inputType="number" name="Porto" /></template> 
                           <template v-if="!isEdit">{{ model.porto }}</template>
                        </div>
                    </div>

                    <div class="columns">
                        <div class="column is-4"><label class="label">Innsender</label></div>
                        <div class="column"> {{ model.innsenderNavn }}</div>
                    </div>

                    <div class="columns">
                        <div class="column is-4"><label class="label">Prosjektnummer</label></div>
                        <div class="column">{{ model.prosjektnummer }}</div>
                    </div>

                    <div class="columns">
                        <div class="column is-4"><label class="label">Merknader</label></div>
                        <div class="column">{{ model.merknader }}</div>
                    </div>

                </div>

                  
                    


                 
 
                <div> 
                </div>
                
            </div>
        </div>

        <hr />
        <div class="field">
            <ValidationProvider rules="">
                <label class="label">Godkjent og utbetalt dato</label>
                <flat-pickr v-model="model.datoUtbetalt"></flat-pickr>
            </ValidationProvider>
        </div>

    </ValidationObserver>



</template>

<script>
import api from "@/api/basicApi.js";
import { FishingTypeObjectEnum } from "@/enums/FishingTypeEnum.js";
export default {
    name: "RegistrerBetaling",
    data: function () {
        return {
            isEdit: false
        }
    },
    props: {
        model: {
            type: Object,
            required: true
        }
    },
    mounted() { },
    computed: {
        calculatedTotal() {
            if (this.model.antProverUtbetalt > 0) {
                let res = this.model.antProverUtbetalt * this.getStykkpris
                if (this.model.porto > 0) {
                    return res + parseInt(this.model.porto)
                }
                return res;
            }
            return 0

        },
        getStykkpris() {
            if (this.model.fishingTypeId == FishingTypeObjectEnum.HØSTFISKE.id) {
                return 100
            } else {
                return 15
            }
        }

    },
    methods: {
        save(successCallback) {
            let self = this;
            self.$refs.form.validate().then(success => {
                if (!success) {
                    return;
                }
                api.saveData({ url: "MottakFiskeskjellOversikt/SaveBetalingsinformasjon", data: this.model }).then(res => {
                    successCallback(res.data)
                });
            })
        },
        setUtregnetBeløp() {
            this.model.belop = this.calculatedTotal
        },

        saveUserInfo() {
            let self  = this;
            let userInfo = {
                id: this.model.innsenderId,
                kontonummer: this.model.innsenderKontonummer,
                porto: this.model.porto
            }
            api.saveData({url: "MottakFiskeskjellOversikt/SaveUserInfo", data: userInfo}).then(()=> {
                self.isEdit = false
            })
        }
    }
}


</script>
<style lang="css" scoped>

.betaling-info .column {
    padding: 0.15rem;
} 
</style>
