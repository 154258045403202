<template>
    <div class="columns">
        <div class="column is-3">
            <div class="control">
            </div>
            <label class="label">Fylke <template v-if="isEdit">*</template> </label>
            <div class="select is-fullwidth">
                <ValidationProvider :rules="{required: isEdit}" v-slot="{ errors }">
                <select v-model.lazy="model.fylkeId" @change="getVassdrag()">
                    <option value=''>-- Velg --</option>
                    <option v-for="fylke in fylker" :key="fylke.id" :value="fylke.id" >{{ fylke.name }}</option>
                </select>
                <p class="help is-danger">{{ errors[0] }}</p>
            </ValidationProvider>
            </div>
        </div>
        <div class="column is-5">
            <label class="label">Vassdrag <template v-if="isEdit">*</template></label>
            <div class="control">
                <ValidationProvider :rules="{required: isEdit}" v-slot="{ errors }">
                    <v-select class="style-chooser" v-model="model.vassdragId" :reduce="item => item.value"
                        placeholder="Velg vassdrag eller skriv for å søke" v-bind:options="vassdragOptions"
                        @search="fetchOptions">
                        <template v-slot:no-options="{ search, searching }">
                            <template v-if="searching">
                                Ingen resultat for <em>{{ search }}</em>.
                            </template>
                            <em v-else style="opacity: 0.5">Ingen vassdrag funnet for valgt fylke</em>
                        </template>
                    </v-select>
                    <p class="help is-danger">{{ errors[0] }}</p>
                </ValidationProvider>
            </div>
        </div>


        <div class="column is-1">
            <label class="label">Fylke</label>
            <div class="control">
                {{ model.fylkeId }}
            </div>
        </div>
        <div class="column is-1">
            <label class="label">Lreg</label>
            <div class="control">
                {{ model.vassdragId }}
            </div>
        </div>
        <div class="column is-3" v-show="angiProvested">
            <field-input v-model="model.funnsted" :disable="textfieldNotEditable"
                label-text="Prøvested uten lregnr"></field-input>
        </div>


    </div>
</template>

<script>
import api from "@/api/basicApi.js";
import fieldInput from '../globals/field-input.vue';
export default {
    components: { fieldInput },
    props: {
        model: {
            type: Object,
            required: true,
        },
        clearSelect: {
            type: Boolean,
            required: false

        },
        isEdit: {
            type:Boolean,
            default: false,
            required: false
        },
        validationRules: {
            type: Object,
            required: false
        },
        angiProvested: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data: function () {
        return {
            fylker: [],
            vassdrag: []
        }
    },

    mounted() {
        var self = this;
        api.fetchData({ url: "Fiskebase/GetFylker" }).then(res => {
            self.fylker = res.data;
        });
        
        if (self.model.fylkeId != "") {
            self.getVassdrag()
        }


    },
    watch: {
        clearSelect(value) {
            if (value) {
                this.$refs.fylkeSelect.clearSelection();
            }
        },
        'model.vassdragId'(value) {
            if (value > 0 || value == null) {
                this.model.funnsted = ""
            }
            if (value > 0 ) {
               this.getFylkeForVassdrag()
            }
            if (this.vassdrag.length === 0) {
                this.getVassdrag()
            }
            
        }
    },
    computed: {
        vassdragOptions() {
            return this.vassdrag.map(item => ({ value: item.id, label: item.name }));
        },
        textfieldNotEditable() {
            return this.model.vassdragId == null || this.model.vassdragId > 0
        }
    },
    methods: {
        getVassdrag() {
            var self = this;
            if (this.model.fylkeId != '') {
                api.fetchData({ url: "Fiskebase/GetVassdragForFylke/" + this.model.fylkeId }).then(res => {
                    self.vassdrag = res.data;
                    self.vassdrag.push({ id: 0, name: 'Uten Lregnr' })

                    let vassdragInList = self.vassdrag.find(v => v.id === self.model.vassdragId)
                    if (!vassdragInList){
                        self.model.vassdragId = null
                    }
                })
            } else {
                self.vassdrag = [];
                self.model.vassdragId = null
                self.model.funnsted = ""
            }            
        },
        fetchOptions(search, loading) {
            if (search.length >= 3 && this.model.fylkeId == "") {
                loading(true);
                var self = this;
                api.fetchData({ url: "Fiskebase/GetVassdragForNavn/" + search }).then(res => {
                    self.vassdrag = res.data;
                    self.vassdrag.push({ id: 0, name: 'Uten Lregnr' })
                    loading(false);
                })

            }
        },
        getFylkeForVassdrag() {
            let self = this 
            api.fetchData({ url: "Fiskebase/GetFylkeIdForVassdrag/" + self.model.vassdragId }).then(res => {
                self.model.fylkeId = res.data.toString();
                
            });
        }

    }
}

</script>

<style>
.v-select .vs--searchable, .v-select .vs__dropdown-toggle {
    min-height: 40px;
}
</style>