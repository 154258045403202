<template>
  <div class="field">
    <label class="label" v-if="showLabel">{{ labelText }}</label>
    <div class="control">
      <div class="select is-fullwidth">
        <select :value="value" @input="input" :disabled="disable" @change="change">
          <option :value="null">-- Velg --</option>
          <option
            v-for="item in options"
            v-bind:value="item.id"
            @input="input"
            v-bind:key="item.id"
            :selected="value != null && value == item.id"
            @change="change"
          >
            {{ item.name }}
          </option>
        </select>
      </div>
      <span class="help is-danger">{{ error }}</span>
      <span class="help">{{ helpText }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    labelText: {
      type: String,
      required: false
    },
    showLabel: {
      type: Boolean,
      required: false,
      default: true
    },
    value: {
      type: [String, Number],
      required: false
    },
    disable: {
      type: Boolean,
      required: false,
      default: false
    },
    options: {
      type: Array,
      default() {
        return [];
      }
    },
    validationRule: {
      type: String,
      required: false
    },
    name: {
      type: String,
      required: false
    },
    error: {
      type: String,
      required: false
    },
    helpText: {
      type: String,
      required: false
    }
  },
  methods: {
    input(event) {
      var value = event.target.value;
      var valueToEmit = value ? parseInt(value) : null;
      this.$emit("input", valueToEmit);
    },
    change(event) {
      var value = event.target.value;
      var valueToEmit = value ? parseInt(value) : null;
      this.$emit("change", valueToEmit);
    }
  }
};
</script>
<style></style>
