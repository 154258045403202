<template>
    <ValidationObserver ref="form">
        <form>
            <div class="field">
                <div class="control">
                    <ValidationProvider rules="required|validYear" v-slot="{ errors }">
                        <field-input label-text="Fangstår *" inputType="number" v-model="model.year"
                            name="Fangstår for prøver" :error="errors[0]" />
                    </ValidationProvider>
                </div>

                <FylkeVassdrag :model="fylkeVassdragModel" :isEdit="true"></FylkeVassdrag>

            </div>
            <div class="field">
                <div class="control">
                    <!-- Hva gjøre når activitytype er unfisk etc.. -->
                    <ValidationProvider rules="required" v-slot="{ errors }">
                        <field-select label-text="Type fiske *" v-model="model.fishingTypeId" :disable=fixedFishingType
                            v-bind:options="fishingTypes" :error="errors[0]" />
                    </ValidationProvider>
                </div>
            </div>

            <div class="field">
                <field-input label-text="Type aktivitet" v-model="model.activity" name="Aktivitet" />
            </div>

            <div class="field">
                <field-input label-text="Redskap" v-model="model.tool" name="Redskap" />
            </div>

            <div class="field">
                <AntallArter :model="model" :is-disabled="false"></AntallArter>
            </div>

            <div class="field">
                <field-input label-text="Løpenr i boks" v-model="model.lopenr" name="Lopenr" />
            </div>

            <div class="field">
                <field-input label-text="Kommentar" v-model="model.comment" name="Kommentar" />
            </div>

            <div class="field">
                <field-input label-text="Annet" v-model="model.annetText" name="Annet" />
            </div>

            <!-- <button class="button is-info mr-1" type="submit"><span class="icon is-small">
              <i class="fa fa-save"></i>
            </span>
            <span>Lagre</span> 
          </button> -->


        </form>

    </ValidationObserver>

</template>

<script>
import api from "@/api/basicApi.js";

import AntallArter from '@/components/grouped/AntallArter.vue';
import FylkeVassdrag from '@/components/grouped/FylkeVassdrag.vue';
import { ActivityTypeEnum } from "../../enums/ActivityTypeEnum";
import { FishingTypeArchiveEnum, FishingTypeObjectEnum, FishingTypeFixed } from "@/enums/FishingTypeEnum.js";

export default {
    name: "LeggTilProver",
    data: function () {
        return {
            fishingTypes: FishingTypeArchiveEnum,
            fylkeVassdrag: {}
        }
    },
    props: {
        model: {
            type: Object,
            required: true
        },
        fylkeVassdragModel: {
            type: Object,
            required: true
        },
        activityTypeId: {
            type: Number,
            required: true
        }
    },
    components: { AntallArter, FylkeVassdrag },
    mounted() {

        if (this.activityTypeId == ActivityTypeEnum.SJOLAKSEFISKE.id) {
            this.model.fishingTypeId = FishingTypeObjectEnum.SJØLAKSEFISKE.id
            this.fishingTypes = FishingTypeFixed
        }
        else if (this.activityTypeId == ActivityTypeEnum.UNGFISKUNDERSOKELSE.id) {
            this.model.fishingTypeId = FishingTypeObjectEnum.UNGFISKUNDERSØKELSE.id
            this.fishingTypes = FishingTypeFixed
        }


    },
    computed: {
        fixedFishingType() {
            if (this.activityTypeId == ActivityTypeEnum.SJOLAKSEFISKE.id || this.activityTypeId == ActivityTypeEnum.UNGFISKUNDERSOKELSE.id) {
                return true
            }
            return false
        }
    },
    methods: {
        save(successCallback) {

            let self = this;
            self.$refs.form.validate().then(success => {
                if (!success) {
                    return;
                }
                else {
                    self.model.vassdragId = self.fylkeVassdragModel.vassdragId
                    api.saveData({ url: "Archive/SaveSampleCollectionForUnit", data: self.model }).then(function () {
                        successCallback(true)
                    })
                }
            })
        }
    }
}


</script>

<style>
@media screen and (min-width: 769px) {

    #formModalProver .modal-content,
    #formModalProver .modal-card {
        width: 80%;
    }
}
</style>
