<template>
    <div class="container">
        <div class="columns">
            <div class="column is-1">
                <field-select label-text="Fangstår" v-model="sampleYear" v-bind:options="sampleYears" />
            </div>
            <div class="column is-6">
                <FylkeVassdrag :model="fylkeVassdrag"></FylkeVassdrag>
            </div>

            <div class="column is-2">
                <field-select label-text="Type fiske" v-model="fishingTypeId" v-bind:options="fishingTypes" />
            </div>
            <div class="column is-2">
                <label class="label">Art</label>
                <v-select multiple label="name" v-model="selectedSpecies" :options="speciesTypes"
                    placeholder="Velg art(er)" />
            </div>
            <div class="column is-1">
                <label class="label">Eksporter</label>
                <div class="dropdown" :class="{ 'is-active': dropdownExpanded }"
                    @click="dropdownExpanded = !dropdownExpanded">
                    <div class="dropdown-trigger">
                        <button class="button" aria-haspopup="true" aria-controls="dropdown-menu">
                            <span class="icon is-small">
                                <i class="fas fa-download" aria-hidden="true"></i>
                            </span>
                            <span class="icon is-small">
                                <i class="fas fa-angle-down" aria-hidden="true"></i>
                            </span>
                        </button>
                    </div>
                    <div class="dropdown-menu" id="dropdown-menu" role="menu">
                        <div class="dropdown-content">
                            <a @click="downloadReport('Skjellprøver')" class="dropdown-item">
                                Mottatte skjellprøver
                            </a>
                            <a @click="downloadReport('OppleggAnalyse')" class="dropdown-item">
                                Status opplegg/analyse
                            </a>

                        </div>
                    </div>
                </div>
            </div>
        </div>


        <table class="is-hidden-mobile table is-fullwidth is-bordered is-striped is-narrow">
            <thead class="has-background-info-light">
                <tr>
                    <th>Fangstår</th>
                    <th>Fylke</th>
                    <th>Vassdrag</th>
                    <th>Type</th>
                    <!-- <th>Arter</th> -->
                    <th><abbr title="Laks">LA</abbr></th>
                    <th><abbr title="Aure">AU</abbr></th>
                    <th><abbr title="Røye">RØ</abbr></th>
                    <th><abbr title="Pukkellaks">PU</abbr></th>
                    <th><abbr title="Regnbueørret">RE</abbr></th>
                    <th><abbr title="Annet">AN</abbr></th>
                    <th>Start opplegg</th>
                    <th>Ferdig opplegg</th>
                    <th>Start analyse</th>
                    <th>Ferdig analyse</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(reg, index) in filteredList" :key="index">
                    <td>{{ reg.sampleYear }}</td>
                    <td> {{ reg.fylkeNavn }}</td>
                    <td>{{ reg.vassdragNavn }}</td>
                    <td>{{ getFishingType(reg.fishingTypeId) }}</td>
                    <!-- <td>{{ reg.samplesAggregatedText }}</td> -->
                    <td>{{ reg.speciesOversiktVm.laks }}</td>
                    <td>{{ reg.speciesOversiktVm.aure }}</td>
                    <td>{{ reg.speciesOversiktVm.røye }}</td>
                    <td>{{ reg.speciesOversiktVm.pukkellaks }}</td>
                    <td>{{ reg.speciesOversiktVm.regnbueørret }}</td>
                    <td>{{ reg.speciesOversiktVm.annet }}</td>
                    <td>
                        <span :class="getSuccessOrGreyText(reg.totalOpplegg, reg.totalSamples)">
                            <i class="fas fa-check"></i>
                        </span>
                        <span class="ml-3">{{ reg.totalOpplegg }}/{{ reg.totalSamples }}</span>
                    </td>
                    <td>
                        <span :class="getSuccessOrGreyText(reg.totalOppleggDone, reg.totalSamples)">
                            <i class="fas fa-check"></i>
                        </span>

                        <span class="ml-3">{{ reg.totalOppleggDone }}/{{ reg.totalSamples }}</span>
                    </td>
                    <td>
                        <span :class="getSuccessOrGreyText(reg.totalAnalysis, reg.totalSamples)">
                            <i class="fas fa-check"></i>
                        </span>
                        <span class="ml-3">{{ reg.totalAnalysis }}/{{ reg.totalSamples }}</span>
                    </td>
                    <td>
                        <span :class="getSuccessOrGreyText(reg.totalAnalysisDone, reg.totalSamples)">
                            <i class="fas fa-check"></i>
                        </span>
                        <span class="ml-3">{{ reg.totalAnalysisDone }}/{{ reg.totalSamples }}</span>
                    </td>
                </tr>
            </tbody>
        </table>


    </div>
</template>

<script>

import api from "@/api/basicApi.js";
import FylkeVassdrag from '../grouped/FylkeVassdrag.vue';
import { FishingTypeEnum } from "@/enums/FishingTypeEnum";
import speciesEnum from "@/enums/SpeciesEnum";
import { PROVEBASE_CONFIG } from "@/../config.js";

export default {

    data: function () {
        return {
            registreringer: [],
            fylkeVassdrag: {
                fylkeId: '',
                vassdragId: null
            },
            fishingTypes: FishingTypeEnum,
            speciesTypes: speciesEnum,
            selectedSpecies: [],
            fishingTypeId: null,
            sampleYears: [],
            sampleYear: null,
            dropdownExpanded: false
        }
    },
    components: {
        FylkeVassdrag
    },
    mounted() {
        let self = this;
        self.getVassdragOversiktAggregert()
        api.fetchData({ url: "MottakFiskeskjellAktivitet/GetSampleYears" })
            .then(res => {
                self.sampleYears = res.data.sampleYears
                self.sampleYear = res.data.defaultYear
            });

    },
    computed: {
        filteredList() {
            if (this.fylkeVassdrag.fylkeId != '' || this.fishingTypeId > 0 || this.fylkeVassdrag.vassdragId != null || this.selectedSpecies.length > 0) {
                // Initial filter function always returns true; will be replaced if any conditions are added
                let filterFunction = () => true;

                // Conditions based on fylkeId, fishingTypeId, and vassdragId
                if (this.fylkeVassdrag.fylkeId != '') {
                    const prevFilter = filterFunction;
                    filterFunction = item => prevFilter(item) && item.fylkeId === this.fylkeVassdrag.fylkeId;
                }

                if (this.fishingTypeId > 0) {
                    const prevFilter = filterFunction;
                    filterFunction = item => prevFilter(item) && item.fishingTypeId === this.fishingTypeId;
                }

                if (this.fylkeVassdrag.vassdragId != null) {
                    const prevFilter = filterFunction;
                    filterFunction = item => prevFilter(item) && item.vassdragId === this.fylkeVassdrag.vassdragId;
                }

                if (this.selectedSpecies.length > 0) {
                    const firstSpeciesCondition = item => item.speciesOversiktVm[this.selectedSpecies[0].id] > 0;

                    // Update filter function to include the first species as an "AND" condition
                    const prevFilter = filterFunction;
                    filterFunction = item => prevFilter(item) && firstSpeciesCondition(item);

                    // If there are additional species, incorporate them as "OR" conditions
                    if (this.selectedSpecies.length > 1) {
                        const additionalSpeciesConditions = this.selectedSpecies.slice(1).map(species =>
                            item => item.speciesOversiktVm[species.id] > 0);

                        const prevFilterWithFirstSpecies = filterFunction;
                        filterFunction = item => prevFilterWithFirstSpecies(item) || additionalSpeciesConditions.some(condition => condition(item));
                    }
                }

                // Use the constructed filter function
                return this.registreringer.filter(filterFunction);
            } else {
                return this.registreringer;
            }
        }
    },
    methods: {
        getSuccessOrGreyText(done, total) {
            if (done === total) {
                return 'has-text-success'
            } else {
                return 'has-text-grey-lighter'
            }
        },
        getFishingType(id) {
            return this.fishingTypes.find(x => x.id == id).name;
        },
        getVassdragOversiktAggregert() {
            let self = this
            if (self.sampleYear != null) {
                api.fetchData({ url: "MottakFiskeskjellOversikt/GetVassdragOversiktAggregert/" + self.sampleYear }).then(res => {
                    self.registreringer = res.data
                });
            }
        },
        downloadReport(type) {
            if (this.sampleYear) {
                api.fetchData({ url: "MottakFiskeskjellOversikt/GetEncryptedToken" }).then(res => {
                    let tokenFromServer = res.data;
                    let queryString = "encryptedToken=" + tokenFromServer;
                    queryString += "&sampleYear=" + this.sampleYear + "&countyId=" + this.fylkeVassdrag.fylkeId
                        + "&vassdragId=" + this.fylkeVassdrag.vassdragId + "&fishingTypeId=" + this.fishingTypeId

                    this.selectedSpecies.forEach(s => { queryString += "&species=" + s.name })

                    if (type == "OppleggAnalyse") {
                        window.location.href = `${PROVEBASE_CONFIG.API_URL}/` + "MottakFiskeskjellOversikt/OppleggAnalyseReport?" + queryString;
                    } else {
                        window.location.href = `${PROVEBASE_CONFIG.API_URL}/` + "MottakFiskeskjellOversikt/VassdragOversiktReport?" + queryString;
                    }
                })
            }
        }

    },
    watch: {

        'sampleYear'(newVal) {
            if (newVal != null) {
                this.getVassdragOversiktAggregert()
            }

        }
    }
};
</script>