<template>
    <div class="container">
        <div v-if="currentRoute == 'aktivitet'">
            <div class="columns">
                <div class="column is-2">
                    <field-select label-text="Fangstår" v-model="sampleYear" v-bind:options="sampleYears" />
                </div>
                <div class="column is-8">
                    <FylkeVassdrag :model="fylkeVassdrag"></FylkeVassdrag>
                </div>
                <div class="column is-2">
                    <field-select label-text="Type fiske" v-model="fishingTypeId" v-bind:options="fishingTypes" />
                </div>
            </div>



            <table class="is-hidden-mobile table is-fullwidth is-bordered is-striped is-narrow">
                <thead class="has-background-info-light">
                    <tr>
                        <th>Fangstår</th>
                        <th>Fylke</th>
                        <th>Vassdrag</th>
                        <th>Nyeste</th>
                        <th>Type</th>
                        <th>Arter</th>
                        <th>Antall prøver</th>
                        <th>Opplegg</th>
                        <th v-if="fiskeskjellRolle">Analyse</th>
                    </tr>
                </thead>


                <tbody>
                    <tr v-for="(vassdrag, index) in filteredList" :key="index">
                        <td> {{ vassdrag.sampleYear }}</td>
                        <td> {{ vassdrag.fylkeNavn }}</td>
                        <td>{{ vassdrag.vassdragNavn }}</td>
                        <td>{{ vassdrag.dateNewestSample | formatDate }} </td>
                        <td> {{ getFishingType(vassdrag.fishingTypeId) }}</td>
                        <td>{{ vassdrag.samplesAggregatedText }}</td>
                        <td>{{ vassdrag.totalNumberOfSamples }}</td>
                        <td><a @click="opplegg(vassdrag)">Velg</a></td>
                        <td v-if="fiskeskjellRolle"><a @click="analyse(vassdrag)">Velg</a></td>

                    </tr>
                </tbody>
            </table>
        </div>
        <div v-if="currentRoute == 'opplegg'">
            <Opplegg></Opplegg>
        </div>

        <div v-if="currentRoute == 'analyse'">
            <Analyse></Analyse>
        </div>


    </div>
</template>
  
<script>

import api from "@/api/basicApi.js";
import FylkeVassdrag from '../grouped/FylkeVassdrag.vue';
import Opplegg from './aktivitet/Opplegg.vue';
import Analyse from './aktivitet/Analyse.vue';
import { FishingTypeEnum }  from "@/enums/FishingTypeEnum";

export default {

    data: function () {
        return {
            fylkeVassdrag: {
                fylkeId: '',
                vassdragId: null
            },
            vassdragListe: [],
            fishingTypes: FishingTypeEnum,
            user: JSON.parse(localStorage.getItem("user")),
            fishingTypeId: null,
            sampleYears: [],
            sampleYear: null
        }
    },
    components: {
        FylkeVassdrag,
        Analyse,
        Opplegg
    },
    mounted() {
        if (this.currentRoute == "aktivitet") { 
            this.getVassdragAktivitetAggregert();
            let self = this;
            api.fetchData({ url: "MottakFiskeskjellAktivitet/GetSampleYears" })
            .then(res => {
                self.sampleYears = res.data.sampleYears
                self.sampleYear = res.data.defaultYear
            });
        }
       
        
    },
    computed: {
        currentRoute() {
            return this.$route.name;
        },
        filteredList() {

            if (this.fylkeVassdrag.fylkeId != '' || this.fishingTypeId > 0 || this.fylkeVassdrag.vassdragId != null) {
                // Create an array to hold the filter conditions
                const filterConditions = [];

                // Check each criteria and add to the filter conditions array if selected
                if (this.fylkeVassdrag.fylkeId != '') {
                    filterConditions.push(item => item.fylkeId === this.fylkeVassdrag.fylkeId);
                }

                if (this.fishingTypeId > 0) {
                    filterConditions.push(item => item.fishingTypeId === this.fishingTypeId);
                }

                if (this.fylkeVassdrag.vassdragId != null) {
                    filterConditions.push(item => item.vassdragId === this.fylkeVassdrag.vassdragId);
                }

                if (filterConditions.length === 0) {
                    return this.vassdragListe;
                }

                // Use the filter method with a combination of filter conditions
                return this.vassdragListe.filter(item => filterConditions.every(condition => condition(item)));
            } else {
                return this.vassdragListe;
            }
        },
        fiskeskjellRolle() {
            return this.user != null && (this.user.accountRoles.includes(1) || this.user.accountRoles.includes(5));
        }
    },
    methods: {
        opplegg(vassdrag) {
             if (vassdrag.vassdragId > 0) {
                this.$router.push({ name: 'opplegg', params: { vassdragId: vassdrag.vassdragId, sampleYear: this.sampleYear } })

             } else {
                this.$router.push({ name: 'opplegg', params: { vassdragId: vassdrag.vassdragId, funnsted: vassdrag.vassdragNavn, fylkeId: vassdrag.fylkeId, sampleYear: this.sampleYear } })
             }
                
        },
        analyse(vassdrag) {
            if (vassdrag.vassdragId > 0) {
                this.$router.push({ name: 'analyse', params: { vassdragId: vassdrag.vassdragId, sampleYear: this.sampleYear } })

             } else {
                this.$router.push({ name: 'analyse', params: { vassdragId: vassdrag.vassdragId, funnsted: vassdrag.vassdragNavn, fylkeId: vassdrag.fylkeId, sampleYear: this.sampleYear } })
             }
        },
        getVassdragAktivitetAggregert() {
            let self = this;
            if (self.sampleYear != null) {
                api.fetchData({ url: "MottakFiskeskjellAktivitet/GetVassdragAktivitetAggregert/" + self.sampleYear })
                .then(res => {
                    self.vassdragListe = res.data
                });
            }
           
        },
        getFishingType(id) {
            return this.fishingTypes.find(x => x.id == id).name;
        }
    },
    watch: {
        '$route'(to) {
            if (to.name == "aktivitet") {
                this.getVassdragAktivitetAggregert()
            }
        },
        'sampleYear' (newVal){
            if (newVal != null) {
                this.getVassdragAktivitetAggregert()
            }
            
        }
    }

};
</script>
  