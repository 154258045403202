<template>
  <popup-modal ref="bulkEditDialog">
    <div class="modal is-active">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Endre mange prøver</p>
          <button class="delete" aria-label="close" @click="close()"></button>
        </header>
        <div class="modal-card-body">


          <div class="notification is-info is-light">
            
            <strong> {{ numberOfSamplesSelected }} </strong> prøver valgt. 
              <p v-if="numberOfSamplesSelected > 200"><strong>NB!</strong> Bare de 200 første vil bli oppdatert</p>
              <p v-else>Velg felt og endre for alle.</p>  
           </div>

          <p v-show="hasValidationErrors" class="help is-danger mb-4">Valgte felt mangler verdi</p>

        
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <input type="checkbox" class="is-inline mr-2" @click="editProvetype = !editProvetype" />
              <label class="label is-inline-block">Prøvetype</label>
            </div>
            <div class="field-body">
              <div class="field" v-if="editProvetype">
                <field-select v-model="sample.sampleMaterialId" v-bind:options="dropdowns.sampleMaterials" />
              </div>
            </div>
          </div>


          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <input type="checkbox" class="is-inline mr-2" @click="editNotes = !editNotes" />
              <label class="label is-inline-block">Notater</label>
            </div>
            <div class="field-body">
              <div class="field" v-if="editNotes">
                <input class="input" type="text" placeholder="" v-model="sample.notes">
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <input type="checkbox" class="is-inline mr-2" @click="editEmpty = !editEmpty" />
              <label class="label is-inline-block">Prøve oppbrukt</label>
            </div>
            <div class="field-body">
              <div class="field" v-if="editEmpty">
                <div class="control">
                    <label class="radio">
                      <input type="radio" name="foobar" v-bind:value="true" v-model="sample.empty" />
                      Ja
                    </label>
                    <label class="radio">
                      <input type="radio" name="foobar" v-bind:value="false" checked v-model="sample.empty" />
                      Nei
                    </label>
                  </div>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <input type="checkbox" class="is-inline mr-2" @click="editSampleDate = !editSampleDate" />
              <label class="label is-inline-block">Prøvetakingsdato</label>
            </div>
            <div class="field-body">
              <div class="field" v-if="editSampleDate">
                <flat-pickr v-model="sample.sampleDate"></flat-pickr>
                <span class="help is-italic">År-måned-dag</span>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <input type="checkbox" class="is-inline mr-2" @click="editDateReceived = !editDateReceived" />
              <label class="label is-inline-block">Dato mottatt</label>
            </div>
            <div class="field-body">
              <div class="field" v-if="editDateReceived">
                <flat-pickr v-model="sample.dateReceived"></flat-pickr>
                <span class="help is-italic">År-måned-dag</span>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <input type="checkbox" class="is-inline mr-2" @click="editStorageMedium = !editStorageMedium" />
              <label class="label is-inline-block">Lagringsmedium</label>
            </div>
            <div class="field-body">
              <div class="field" v-if="editStorageMedium">
                <field-select v-model="sample.storageMediumId" v-bind:options="dropdowns.storageMediums" />
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <input type="checkbox" class="is-inline mr-2" @click="editDateStoredOnMedium = !editDateStoredOnMedium" />
              <label class="label is-inline-block">Dato lagret på medium</label>
            </div>
            <div class="field-body">
              <div class="field" v-if="editDateStoredOnMedium">
                <flat-pickr v-model="sample.dateStoredOnMedium"></flat-pickr>
                <span class="help is-italic">År-måned-dag</span>
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <input type="checkbox" class="is-inline mr-2" @click="editStorageLocation = !editStorageLocation" />
              <label class="label is-inline-block">Lagringssted</label>
            </div>
            <div class="field-body">
              <div class="field" v-if="editStorageLocation">
                <field-select v-model="sample.storageLocationId" v-bind:options="dropdowns.storageLocations" />
              </div>
            </div>
          </div>

          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <input type="checkbox" class="is-inline mr-2" @click="editCollectedBy = !editCollectedBy" />
              <label class="label is-inline-block">Samlet av</label>
            </div>
            <div class="field-body">
              <div class="field" v-if="editCollectedBy">
                <input class="input" type="text" placeholder="" v-model="sample.collectedBy">
              </div>
            </div>
          </div>
        

          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <input type="checkbox" class="is-inline mr-2" @click="editProject = !editProject" />
              <label class="label is-inline-block">Prosjekt</label>
            </div>
            <div class="field-body">
              <div class="field" v-if="editProject">
                <field-select v-model="sample.projectId" v-bind:options="dropdowns.projects" />
              </div>
            </div>
          </div>

        </div>

        <footer class="modal-card-foot">
          <button class="button is-info" @click="bulkEdit"><span class="icon is-small">
              <i class="fa fa-save"></i>
            </span>
            <span>Endre</span> 
          </button>
          <button class="button" @click="close()">
            <span class="icon is-small">
              <i class="fa fa-times"></i>
            </span>
            <span>Avbryt</span>
          </button>
        </footer>
      </div>
    </div>
  </popup-modal>
</template>

<script>

import api from "@/api/basicApi.js";
import PopupModal from './PopupModal.vue'
export default {
  name: "BulkEditSamples",
  data: function () {
    return {
      sample: {},
      dropdowns: [],
      editProvetype: false,
      editNotes: false,
      editStorageMedium: false,
      editProject: false,
      editEmpty: false,
      editSampleDate: false,
      editDateReceived: false,
      editDateStoredOnMedium: false,
      editStorageLocation: false,     
      hasValidationErrors: false,
      editCollectedBy: false
    };
  },
  components: { PopupModal,
   
   },

  props: {
    numberOfSamplesSelected: {
      type: Number,
      required: false
    }
  },
  mounted() {
    let self = this
    this.hasValidationErrors = false;
    api.fetchData({ url: "Prove/GetDropdownsForSampleBulkEdit" }).then(res => {
      self.dropdowns = res.data;
    })

  },
  methods: {
    show() {
      this.$refs.bulkEditDialog.open();
    },
    bulkEdit() {
      let hasSelectedFieldsValues = this.validateEditValues()
      if (hasSelectedFieldsValues) {
        
        this.$emit('valuesToUpdate', this.sample)
        this.$refs.bulkEditDialog.close()
        this.initBulkEdit()
      } else {
        this.hasValidationErrors = true
      }

    },
    close() {
      this.$refs.bulkEditDialog.close()
      this.initBulkEdit()
    },

    validateEditValues() {
      if (this.editProvetype && this.sample.sampleMaterialId == null) return false
      if (this.editProject && this.sample.projectId == null) return false
      if (this.editSampleDate && this.sample.sampleDate == null) return false
      if (this.editDateReceived && this.sample.dateReceived == null) return false
      if (this.editDateStoredOnMedium && this.sample.dateStoredOnMedium == null) return false
      if (this.editStorageLocation && this.sample.storageLocationId == null) return false
      if (this.editNotes && this.sample.notes == null) return false
      if (this.editStorageMedium && this.sample.storageMediumId == null) return false
      return true
    },

    initBulkEdit() {
      this.sample = {},
        this.editProvetype = false,
        this.editProject = false,
        this.editSampleDate = false,
        this.editDateReceived = false,
        this.editDateStoredOnMedium = false,
        this.editCollectedBy = false,
        this.editStorageMedium = false; 
        this.editStorageLocation = false,
        this.editNotes = false,
        this.hasValidationErrors = false
    },
   
  },
  watch: {

  }
};
</script>

<style scoped>
.field-label {
  flex-grow: 2;
  text-align: left;
}
</style>
