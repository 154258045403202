import { render, staticRenderFns } from "./LinkMottak.vue?vue&type=template&id=ae6c1004&scoped=true&"
import script from "./LinkMottak.vue?vue&type=script&lang=js&"
export * from "./LinkMottak.vue?vue&type=script&lang=js&"
import style0 from "./LinkMottak.vue?vue&type=style&index=0&id=ae6c1004&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ae6c1004",
  null
  
)

export default component.exports