const ActivityTypeArrayEnum = Object.freeze([
    { id: 1, name: "Elvefiske - voksen fisk" },
    { id: 2, name: "Sjølaksefiske" },
    { id: 3, name: "Ungfiskundersøkelse" },    
    { id: 4, name: "Diverse prøver" },
   
 ]);

 const ActivityTypeEnum = Object.freeze({
    ELVEFISKE: { id: 1, name: "Elvefiske - voksen fisk" },
    SJOLAKSEFISKE: { id: 2, name: "Sjølaksefiske" },
    UNGFISKUNDERSOKELSE: { id: 3, name: "Ungfiskundersøkelse" },
    DIVERSE_PROVER: { id: 4, name: "Diverse prøver" },
  });

export { ActivityTypeEnum, ActivityTypeArrayEnum }


  