<template>
  <div class="hello">
    <div class="modal is-active">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Koble til prøve</p>
          <button class="delete" aria-label="close" @click="close()"></button>
        </header>
        <section class="modal-card-body">
          <div class="columns">
            <div class="column">
              <label class="label">Søk i prøver på mottaksfryser</label>
              <div class="field is-grouped">
                <p class="control is-expanded">
                  <input class="input" type="text" v-model="guid" placeholder="QR-kode"/>
                </p>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <table class="table is-narrow is-fullwidth is-bordered is-striped">
                <thead slot="head" class="has-background-info-light">
                  <th>Dato</th>
                  <th>Art</th>
                  <th>Prøvetype</th>
                  <th>Ansvarlig</th>
                  <th>Lagringssted</th>
                  <th>Kommentar</th>
                  <th>Registrert av</th>
                  <th>Velg</th>
                </thead>
                <tbody>
                  <tr v-for="row in filteredList" :key="row.id">
                    <td>{{ row.checkinDate | formatDate }} </td>
                    <td>{{ row.speciesName }}</td>
                    <td>{{ row.sampleMaterialName }}</td>
                    <td>{{ row.projectLeader }}</td>
                    <td>{{ row.storageLocationName }}</td>
                    <td>{{ row.comment }}</td>
                    <td>{{ row.createdBy }}</td>
                    <td><input type="checkbox" @click="velgEnhet(row.id)" :value="row.id" :disabled="valgtEnhet > 0 && row.id != valgtEnhet"/></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>


        </section>
        <footer class="modal-card-foot">
          <button class="button is-success" @click="link()" :disabled="valgtEnhet == null">
            <span class="icon is-small">
              <i class="fa fa-link"></i>
            </span>
            <span>Koble</span>
          </button>
          <button class="button" @click="close()">
            Avbryt
          </button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api/basicApi.js";
export default {
  name: "LinkMottak",
  data: function () {
    return {
      showLinkModal: false,
      guid: "",
      enheter: [],
      valgtEnhet: null

    };
  },
  props: {
    speciesId: {
      type: Number,
      required: false
    }

  },
  computed: {
    filteredList() {    
      if (this.guid === '') {
        return this.enheter
      }
      return this.enheter.filter(e => { return e.guid == this.guid.toLowerCase()})
    }
  },
  mounted() {
    let self = this;
      api.fetchData({ url: "Mottak/GetMottakUnitsForSpecies/" + this.speciesId }).then(res => {
      self.enheter = res.data
    })
  },
  methods: {
    close() {
      this.$emit("showModal", false);
    },
    link() {
      this.$emit("link", this.valgtEnhet);
      this.$emit("showModal", false);
    },
    velgEnhet(id) {
      if (id == this.valgtEnhet){
        this.valgtEnhet = null
      } else {
        this.valgtEnhet = id
      }
    }
  }
};
</script>

<style scoped>
.modal-card {
  width: 80%;
  height: 100%;
} 
</style>
