<template>
  <div class="mt-3" v-cloak v-show="loadingFinished && speciesId != null && samplesForSpecies.length > 0">
    
    <div class="columns">
      <div class="column"><h2 class="subtitle is-inline-block">Standardprøver</h2></div>
    </div>
         <div class="columns">
          <div class="column is-3">
                <ValidationProvider>
                  <label class="label">Prøvetakingsdato</label>
                  <flat-pickr v-model="provetakingDatoSamples"></flat-pickr>
                </ValidationProvider>
              </div>
              <div class="column is-3">
                <ValidationProvider>
                  <label class="label">Dato mottatt</label>
                  <flat-pickr v-model="datoMottattSamples"></flat-pickr>
                </ValidationProvider>
              </div>
              <div class="column is-3">
                <ValidationProvider>
                  <field-select label-text="Prosjekt" v-model="projectIdSamples" v-bind:options="projects"/>
                </ValidationProvider>
              </div>
            </div>

          <table class="table is-fullwidth is-bordered is-striped is-narrow has-no-padding">
            <thead class="">
                <tr class="is-center">
                    <th></th>
                    <th>Type</th>
                    <th>Medium</th>
                    <th>Lagringssted</th>
                    <th>Prøve id</th>
                </tr>
            </thead>
            <tbody>
              <tr v-for="(reg, index) in samplesForSpecies" :key="index">
                <td>
                  <label class="checkbox ml-3 mt-2" >
                    <a @click="removeSample(index)">Fjern</a>
                  </label>
                </td>  
                <td> 
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <field-select v-bind:options="sampleMaterial" v-model="reg.sampleMaterialId" :error="errors[0]"/>
                  </ValidationProvider>
                </td>
                <td>
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <field-select v-bind:options="storageMediums" v-model="reg.storageMediumId" :error="errors[0]"/>
                  </ValidationProvider>
                </td>
                <td>
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <field-select v-bind:options="storageLocations" v-model="reg.storageLocationId" :error="errors[0]"/>
                  </ValidationProvider>
                </td>
                <td>
                  <field-input v-model="reg.customId"></field-input>  
                </td>
             
              </tr>
              </tbody>
          </table>
          <button class="button is-small" @click="newSample">Legg til</button>
  </div>
</template>

<script>
import api from "@/api/basicApi.js";

export default {
  name: "SampleTemplate",
  data: function () {
    return {
      
      samplesForSpecies: [],
      projects: [],
      sampleMaterial: [],
      storageMediums: [],
      storageLocations: [],
      datoMottattSamples: null,
      provetakingDatoSamples: null,
      projectIdSamples: null,
      loadingFinished: false

    };
  },
  props: {
    speciesId: {
      type: Number,
      required: false
    },
    individualCategoryId: {
      type: Number,
      required: false

    },
    globalTypeId: {
      type:Number,
      required: false
    },
    //Dato registrert
    dateCreated: {
      type: String,
      required: false
    }
  },
  components: {
  },
  mounted() {
   
    

  },
  methods: {
      getTemplateValuesSamplesForSpecies() {
        let self = this
        this.loadingFinished = false
        
        api.fetchData({ url: "Prove/RegisterSample/" + this.globalTypeId }).then(res => {
          self.projects = res.data.projects;
          self.sampleMaterial = res.data.sampleMaterials;
          self.storageMediums = res.data.storageMediums;
          self.storageLocations = res.data.storageLocations;

          let url =  "Prove/GetSampleTemplatesForSpecies/" + this.speciesId;
          if (this.individualCategoryId) {
            url += "/" + this.individualCategoryId
          }

          api.fetchData({ url: url}).then(res => {
            self.samplesForSpecies = res.data
            self.loadingFinished = true
          });
        });
      },


    removeSample(index) {
       this.samplesForSpecies.splice(index, 1)
    },

    getSamples() {
      let self = this
            
      this.samplesForSpecies.forEach(function (sample) {
          sample.projectId = self.projectIdSamples;
          sample.dateReceived = self.datoMottattSamples;
          sample.sampleDate = self.provetakingDatoSamples;
      });

      return this.samplesForSpecies;
    },

    newSample() {
      var maxIdSampleList = Math.max.apply(Math, this.samplesForSpecies.map(function(sample) { return sample.id; }))
      var newSample = {
        id: maxIdSampleList + 1, 
        sampleMaterialId:  null,
        storageLocationId: null,
        storageMediumId: null
      }
      this.samplesForSpecies.push(newSample);
    },



  },
  computed: {},
  watch: {
    speciesId: {
      immediate: true, 
      handler(newId, oldId) {
        if (this.globalTypeId != null && this.speciesId != null){
          if (newId !== oldId) {
            this.getTemplateValuesSamplesForSpecies(); 
          }
        }
        
      }
    },
    individualCategoryId: {
      handler(newId, oldId) {
        if (this.globalTypeId != null && this.speciesId != null && newId != null){
          if (newId !== oldId) {
            this.getTemplateValuesSamplesForSpecies(); 
          }
        }
        
      }
    },
    dateCreated: {
      handler(newDate, oldDate) {
        if (newDate != oldDate) {
          this.datoMottattSamples = newDate
        }
      }
    }
   
  },
};
</script>

<style scoped>
.table.has-no-padding td {
 padding: 0
}
.table td.is-center {
  text-align: center;
  vertical-align: middle
}
[v-cloak] {
      display: none; 
    }
</style>
