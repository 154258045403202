<template>
  <popup-modal ref="formModal">
    <div class="modal is-active">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ title }}</p>
          <button class="delete" aria-label="close" @click="close()"></button>
        </header>
        <div class="modal-card-body">
          <slot></slot>
        </div>
        <footer class="modal-card-foot">
          <button class="button is-info" type="button" @click="submitForm">
          <span class="icon is-small"><i class="fa fa-save"></i></span>
          <span>Lagre</span>
        </button>
          <button class="button" type="button" @click="close()">
            <span class="icon is-small">
              <i class="fa fa-times"></i>
            </span>
            <span>Avbryt</span>
          </button>
        </footer>
      </div>
    </div>
  </popup-modal>
</template>

<script>

import PopupModal from './PopupModal.vue'
export default {
  name: "FormModal",
  data: function () {
    return {
    };
  },
  components: { PopupModal },

  props: {
    title: {
      type: String,
      required: false
    }
  },
  mounted() {},
  methods: {
    show() {
      this.$refs.formModal.open();
    },
    
    close() {
      this.$refs.formModal.close()
      
    },
    submitForm() {
      this.$emit('submit');
    }
  }
   };
</script>

<style scoped>
.field-label {
  flex-grow: 2;
  text-align: left;
}
</style>
