<template>
  <div>
    <section class="section">
      <div class="container">


        <div class="columns is-mobile">
          <div class="column">
            <div class="box">
              <individual-info :animal="animal" v-if="!editIndividual"></individual-info>
              <create-edit-individual v-if="editIndividual" :animal="animal" :title="'Registrer individ'"
                @showIndividAndSamples="showIndividAndSamples"></create-edit-individual>
            </div>
          </div>
        </div>




        <prove v-if="registerSample" :sampleId="showSampleId" :globalType="animal.speciesGlobalTypeId"
          @sampleUpdated="updateSamplesForIndividual" @cancel="cancel" class="mb-5"></prove>

        <div class="box" v-if="showTemplates">
          <ValidationObserver ref="form">
            <form @submit.prevent="saveTemplateSamples, save">
              <sample-template ref="sampleTemplateIndividual" :speciesId="animal.speciesId"
                :globalTypeId="animal.speciesGlobalTypeId"
                :individualCategoryId="animal.individualCategoryId"></sample-template>
              <div class="columns mt-3">
                <div class="column">
                  <div class="is-pulled-right">
                    <button type="submit" @click="saveTemplateSamples" class="button is-info">
                      <span class="icon is-small">
                        <i class="fa fa-save"></i>
                      </span>
                      <span>Lagre</span>
                    </button>

                    <button type="button" class="button is-default ml-2" v-on:click="showTemplates = false">
                      <span class="icon is-small">
                        <i class="fa fa-times"></i>
                      </span>
                      <span>Lukk</span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>

        <div class="columns" v-show="this.$route.params.individualId > 0">

          <div class="column">
            <div class="box">
              <div class="columns">
                <div class="column is-half">
                  <h3 class="subtitle">Prøver</h3>
                </div>
                <div class="column is-half">
                  <button class="button is-pulled-right ml-1" v-show="templateExists" @click="showSamplesTemplate()"
                    type="button">
                    <span class="icon is-small">
                      <i class="fa fa-plus"></i>
                    </span>
                    <span>Standardprøver</span>
                  </button>
                  <button class="button is-pulled-right" @click="createSample()" type="button">
                    <span class="icon is-small">
                      <i class="fa fa-plus"></i>
                    </span>
                    <span>Ny prøve</span>
                  </button>
                </div>
              </div>
              <table class="table is-fullwidth is-bordered is-striped is-narrow">
                <thead class="is-size-7">
                  <tr>
                    <th>Prøvetype</th>
                    <th>Medium</th>
                    <th>Lagringssted</th>
                    <th>Oppbrukt</th>
                    <th>Prøvetakingsdato</th>
                    <th>Dato mottatt</th>
                    <th>Dato medium</th>
                    <th>Prosjekt</th>
                    <th>Prosjektleder</th>
                    <th>Oppdragsgiver</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="sample in samples" v-bind:key="sample.id">
                    <td>{{ sample.sampleMaterialName }}</td>
                    <td>{{ sample.storageMediumName }}</td>
                    <td>{{ sample.storageLocationName }}</td>
                    <td>
                      <span v-show="sample.empty">Ja</span><span v-show="!sample.empty">Nei</span>
                    </td>
                    <td>{{ sample.sampleDate | formatDate }}</td>
                    <td>{{ sample.dateReceived | formatDate }}</td>
                    <td>{{ sample.dateStoredOnMedium | formatDate }}</td>
                    <td>{{ sample.projectName }}</td>
                    <td>{{ sample.projectLead }}</td>
                    <td>
                      <div v-show="sample.numberOfOwners > 0" class="
                      has-tooltip-info
                      has-tooltip-arrow
                      has-tooltip-right
                      has-tooltip-multiline
                    " v-bind:value="sample.numberOfOwners" v-bind:data-tooltip="sample.ownerNames">
                        {{ sample.numberOfOwners }}
                      </div>
                    </td>
                    <td>
                      <a v-show="isAdminOrHjorteviltRolle && animal.source != 3"
                        @click="showSample(sample.id)">Endre</a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>

        <a class="button is-info is-outlined mt-5" @click="$router.back()">Tilbake</a>
      </div>
    </section>
  </div>
</template>

<script>
import IndividualInfo from "@/components/IndividualInfo.vue";
import CreateEditIndividual from "@/components/CreateEditIndividual.vue";
import Prove from "@/components/Prove.vue"
import SamplesTemplate from "@/components/SamplesTemplate.vue";
import api from "@/api/basicApi.js";

export default {
  data: function () {
    return {
      animal: {
        id: 0,
        sexId: null,
        ageId: null,
        individualCategoryId: null,
        speciesId: null,
        mottakId: null,
        speciesGlobalTypeId: null,
        municipalityId: null,
        wildRaindeerAreaId: null
      },
      samples: [],
      editIndividual: false,
      registerSample: false,
      showSampleId: null,
      showTemplates: false,
      templateExists: true
    };
  },
  mounted() {

    let self = this;
    var individId = parseInt(this.$route.params.individualId);

    if (individId > 0) {

      self.showIndivid = true;
      api.fetchData({ url: "Individ/" + individId }).then((res) => {
        self.animal = res.data;
        api.fetchData({ url: "Individ/HasSampleTemplate/" + self.animal.speciesId }).then((res) => {
        self.templateExists = res.data;
      });

      });
      api.fetchData({ url: "Individ/GetSamples/" + individId }).then((res) => {
        self.samples = res.data;
      });
     
      

    } else {
      self.editIndividual = true
    }

    self.$root.$on("closeCreateEdit", function () {
      self.editIndividual = false;
    });

    self.$root.$on("edit", function () {
      self.editIndividual = true;
    });

    self.$root.$on("cancelUpdateIndivid", function () {
      self.editIndividual = false;
    });

    self.$root.$on("updateIndivid", function () {
      self.update();
    });
  },
  methods: {
    showIndividAndSamples() {
      let self = this
      var individId = parseInt(this.$route.params.individualId);
      self.editIndividual = false
      api.fetchData({ url: "Individ/" + individId }).then((res) => {
        self.animal = res.data;
      });
      api.fetchData({ url: "Individ/GetSamples/" + individId }).then((res) => {
        self.samples = res.data;
      });
    },
    update() {
      let self = this;
      this.$refs.form.validate().then(success => {
        if (!success) {
          alert("Valideringsfeil");
          return;
        } else {
          api
            .saveData({ url: "Prove/UpdateIndividual", data: self.animal })
            .then(res => {
              self.animal = res.data;
              self.editIndividual = false;
            });
        }
      });
    },
    createSample() {
      this.showSampleId = 0
      this.registerSample = true
    },
    showSample(sampleId) {
      this.showSampleId = sampleId;
      this.registerSample = true;

    },
    updateSamplesForIndividual() {
      let self = this
      api.fetchData({ url: "Individ/GetSamples/" + this.$route.params.individualId }).then((res) => {
        self.samples = res.data;
      });

      this.registerSample = false
    },
    cancel() {
      this.registerSample = false
    },
    showSamplesTemplate() {
      this.showTemplates = true
    },
    saveTemplateSamples() {

      let self = this;
      self.$refs.form.validate().then(success => {
        if (!success) {
          return;
        }
        else {
          var samplesToSave = this.$refs.sampleTemplateIndividual.getSamples();
          samplesToSave.map(s => s.individualId = this.$route.params.individualId)
          api.saveData({ url: "Prove/SaveSamplesFromTemplate", data: samplesToSave }).then(() => {
            self.updateSamplesForIndividual()
            self.showTemplates = false;
          })
          
        }
      })
    }
  },
  components: {
    "individual-info": IndividualInfo,
    "create-edit-individual": CreateEditIndividual,
    "prove": Prove,
    "sample-template": SamplesTemplate
  },
  computed: {
    isAdminOrHjorteviltRolle() {
      const user = JSON.parse(localStorage.getItem("user"));
      if (user.accountRoles.includes(1) || user.accountRoles.includes(2)) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style>
.is-underlined {
  text-decoration: underline;
}
</style>
