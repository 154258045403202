<template>
    <div class="container">
        <section class="section">
        <div v-if="currentRoute == 'units'">
        <div class="columns">
            <div class="column is-2">
                <field-select label-text="Fangstår" v-model="sampleYear" v-bind:options="sampleYears" />
            </div>
            <div class="column is-10">
                <FylkeVassdrag :model="fylkeVassdrag"></FylkeVassdrag>
            </div>
        </div>
        
        <div>
            <button type="button" class="button is-info is-pulled-right mb-2" @click="showFormModal()">
                <span class="icon is-small">
                    <i class="fa fa-plus"></i>
                </span>
                <span>Ny boks</span>
            </button>
        </div>

        <table class="is-hidden-mobile table is-fullwidth is-bordered is-striped is-narrow">
            <thead class="has-background-info-light">
                <tr>
                    <th>Fylke</th>
                    <th>Vassdrag</th>
                    <th>Fangstår</th>
                    <th>Boksnr</th>
                    <th>Reol</th>
                    <th><abbr title="Laks">LA</abbr></th>
                    <th><abbr title="Aure">AU</abbr></th>
                    <th><abbr title="Røye">RØ</abbr></th>
                    <th><abbr title="Pukkellaks">PU</abbr></th>
                    <th><abbr title="Regnbueørret">RE</abbr></th>
                    <th><abbr title="Annet">AN</abbr></th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(reg, index) in filteredList" :key="index">
                    <td> {{ reg.countyName }}</td>
                    <td> {{ reg.fiskeskjellSamplesAggregated.vassdragName }}</td>
                    <td>{{ reg.fiskeskjellSamplesAggregated.sampleYears }}</td>
                    <td>{{ reg.countyId }}-{{ reg.serialNumber }}</td>
                    <td>{{ reg.skjellarkivReolId }}</td>
                    <td>{{ reg.fiskeskjellSamplesAggregated.laks }}</td>
                    <td>{{ reg.fiskeskjellSamplesAggregated.aure }}</td>
                    <td>{{ reg.fiskeskjellSamplesAggregated.røye }}</td>
                    <td>{{ reg.fiskeskjellSamplesAggregated.regnbueørret }}</td>
                    <td>{{ reg.fiskeskjellSamplesAggregated.pukkellaks }}</td>
                    <td>{{ reg.fiskeskjellSamplesAggregated.unknownSpecies }}</td>
                    <td><a @click="showUnit(reg)">Velg</a></td> 
                </tr>
            </tbody>
        </table>
    </div>

    

    <form-modal id="formModalBoks" ref="formModal" title="Opprett ny boks" @submit="handleSubmit">
            <component :is="leggTilBoksForm" :model="boks" ref="formComponent" ></component>
    </form-modal>


    <div v-if="currentRoute == 'unit'">
        <Unit></Unit>
    </div>

</section>
    </div>
</template>

<script>
import api from "@/api/basicApi.js";
import FylkeVassdrag from '@/components/grouped/FylkeVassdrag.vue';
import { FishingTypeEnum } from "@/enums/FishingTypeEnum";
import Unit from "@/components/archive/Unit.vue"
import FormModal from '@/components/modals/FormModal.vue';
import LeggTilBoks from "../components/archive/LeggTilBoks.vue";

export default {

    data: function () {
        return {
            registreringer: [],
            fylkeVassdrag: {
                fylkeId: '',
                vassdragId: null
            },
            fishingTypes: FishingTypeEnum,
            fishingTypeId: null,
            sampleYears: [],
            sampleYear: null,
            dropdownExpanded: false,
            selectedUnit: {},
            leggTilBoksForm: LeggTilBoks,
            boks: this.initBoks()    
            
        }
    },
    components: {
        FylkeVassdrag, Unit, "form-modal": FormModal, LeggTilBoks
    },
    mounted() {
        let self = this;
        self.getUnitsAggregert()
        api.fetchData({ url: "Archive/GetSampleYears" })
            .then(res => {
                self.sampleYears = res.data.sampleYears
            });

    },
    computed: {
        currentRoute() {
            return this.$route.name;
        },

        filteredList() {
            if (this.fylkeVassdrag.county != '' || this.fishingTypeId > 0 || this.fylkeVassdrag.vassdragId != null) {
                // Initial filter function always returns true; will be replaced if any conditions are added
                let filterFunction = () => true;

                // Conditions based on fylkeId, fishingTypeId, and vassdragId
                if (this.fylkeVassdrag.fylkeId != '') {
                    const prevFilter = filterFunction;
                    filterFunction = item => prevFilter(item) && item.countyId === this.fylkeVassdrag.fylkeId;
                }

                if (this.fishingTypeId > 0) {
                    const prevFilter = filterFunction;
                    filterFunction = item => prevFilter(item) && item.fishingTypeId === this.fishingTypeId;
                }

                if (this.fylkeVassdrag.vassdragId != null) {
                    const prevFilter = filterFunction;
                    filterFunction = item => {
                        if (!Array.isArray(item.fiskeskjellSamplesAggregated.vassdragIds)) {
                            return false;
                        }
                        return prevFilter(item) && item.fiskeskjellSamplesAggregated.vassdragIds.includes(Number(this.fylkeVassdrag.vassdragId));
                    };
                }

                // Use the constructed filter function
                return this.registreringer.filter(filterFunction);
            } else {
                return this.registreringer;
            }
        }
    },
    methods: {
        showUnit(unit) {
            this.selectedUnit = unit
            this.$router.push({ name: 'unit', params: { unitId: unit.id } })
        },
        getFishingType(id) {
            return this.fishingTypes.find(x => x.id == id).name;
        },
        getUnitsAggregert() {
            let self = this
            api.fetchData({ url: "Archive/GetStorageUnits/" + self.sampleYear }).then(res => {
                self.registreringer = res.data
            });
            
        },
        showFormModal() {
            this.boks = this.initBoks()
            this.$refs.formModal.show();
        },
        handleSubmit() {
            let self = this;
            const formComponent = self.$refs.formComponent;

            formComponent.save((data) => {
                self.showUnit(data)
                self.$refs.formModal.close();
            });
        },
        initBoks() {
            return {
                fylkeId: "",
                activityId: null
            }
        }

    },
    watch: {
        'sampleYear'() {
            this.getUnitsAggregert()
        }
    }
};
</script>