<template>
    <ValidationObserver ref="form">
        <form>
            <div class="field">
                <div class="control">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                        <label class="label">Fylke * </label>
                        <div class="select is-fullwidth">
                            <select v-model.lazy="model.fylkeId">
                                <option value=''>-- Velg --</option>
                                <option v-for="fylke in fylker" :key="fylke.id" :value="fylke.id">{{ fylke.name }}
                                </option>
                            </select>
                            <p class="help is-danger">{{ errors[0] }}</p>
                            </div>
                    </ValidationProvider>
                </div>

            </div>
            <div class="field">
                <div class="control">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                        <field-select label-text="Aktivitet *" v-model="model.activityId" v-bind:options="activityTypes"
                            :error="errors[0]" />
                    </ValidationProvider>
                </div>
            </div>
        </form>
    </ValidationObserver>
</template>

<script>
import api from "@/api/basicApi.js";
import { ActivityTypeArrayEnum } from "@/enums/ActivityTypeEnum.js";
export default {
    name: "RegistrerBoks",
    data: function () {
        return {
            activityTypes: ActivityTypeArrayEnum,
            fylker: []
        }
    },
    props: {
        model: {
            type: Object,
            required: true
        }
    },

    mounted() {
        var self = this;
        api.fetchData({ url: "Fiskebase/GetFylker" }).then(res => {
            self.fylker = res.data;
        });
    },
    methods: {

        save(successCallback) {
            let self = this;

            self.$refs.form.validate().then(success => {
                if (!success) {
                    return;
                }
                else {
                    var boksToSave = {
                        countyId: self.model.fylkeId,
                        activityTypeId: self.model.activityId
                    }
                    api.saveData({ url: "Archive/CreateUnit", data: boksToSave }).then(res => {
                        successCallback(res.data);
                    })
                }
            });
        }
    }
}


</script>
